import './App.scss';
import { AppRoutes } from './routes';


function App() {
  return (
    <div>
      <AppRoutes/>
    </div>
  );
}

export default App;
