import * as formik from 'formik';
import * as yup from 'yup';

import { useEffect, useState } from 'react';

import AppButton from '../../../commons/buttons/AppButton';
import AppInput from '../../../commons/inputs/AppInput';
import AppLinkButton from '../../../commons/buttons/AppLinkButton';
import AppLoading from '../../../commons/AppLoading';
import CompanyAPI from '../CompanyAPI';
import CustomCard from '../../../commons/cards/Card';
import { Form } from 'react-bootstrap';
import HallAppCenterContainer from '../../../commons/containers/HallAppCenterContainer';
import HallAppContainer from '../../../commons/containers/HallAppContainer';
import HallAppToast from '../../../commons/HallAppToast';
import axios from 'axios';
import { fetchCompanies, selectedCompany } from '../../../redux/actions/companyAction';
import success from '../../../assets/images/sucesso.png'
import { useDispatch } from 'react-redux';
import { useMask } from '@react-input/mask';
import UserService from '../../../auth/UserService';

function CompanyForm({ company, closeModal, hasUpdated }) {

    const dispatch = useDispatch();

    const service = new CompanyAPI();
    const userAPI = new UserService();

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setSuccess] = useState(false)
    const [address, setAddress] = useState(false)
    const [zipcode, setZipcode] = useState('');
    const [disabledInputZipcode, setDisabledInputZipcode] = useState(false);
    const [initialValues, setInitialValues] = useState({
        name: '',
        zipcode: '',
        number: '',
        unit: ''
    });
    const [isFirstCompany, setFirstCompany] = useState(false)


    const { Formik } = formik;

    const schema = yup.object().shape({
        name: yup.string().required('Nome é obrigatório').max(120, 'Nome deve ter no maximo 50 cracteres'),
        zipcode: yup.string().required('CEP é obrigatório'),
        number: yup.string().required('Número é obrigatório'),
        unit: yup.string()
    });

    useEffect(() => {
        const companies = localStorage.getItem("companies")
        setFirstCompany(companies === null || companies === undefined)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (company) {
            const address = {
                street: company?.street,
                district: company?.district,
                state: company?.state,
                city: company?.city
            }
            setAddress(address)
            setInitialValues({
                name: company.name || '',
                nickname: company.nickname || '',
                zipcode: formatZipCode(company.zipcode) || '',
                number: company.number || '',
                unit: company.unit || ''
            });
        }
    }, [company]);

    function formatZipCode(zipCode) {
        const cleaned = ('' + zipCode).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{5})(\d{0,3})$/);

        if (match) {
            return `${match[1]}-${match[2]}`;
        }
        return zipCode;
    }


    const save = async (values, { setErrors }) => {
        if (address === null) {
            HallAppToast.error('Preencha um CEP válido e tente novamente.');
        } else {
            setIsLoading(true)
            const data = {
                ...company,
                id: company?.id,
                ...values,
                address: {
                    ...address,
                    number: values.number,
                    zipcode: values.zipcode.replaceAll('-', '')
                }
            }
            if (company != null && company.id !== null) {
                service.updateCompany(company.id, data)
                    .then(async (_) => {
                        HallAppToast.success();
                        //Chama serviço para atualizar lista de empresas, refatorar e fzer via redux
                        if(hasUpdated) {
                            hasUpdated();
                        }
                    }).catch((_) => {
                        HallAppToast.error();
                    }).finally((_) => setIsLoading(false))
            } else {
                service.createCompany('BASIC_DATA', data)
                    .then(async (resp) => {
                        HallAppToast.success();
                        setSuccess(true)
                        data.id = resp.data.id;
                        service.setCompanyManagment(data)
                        dispatch(selectedCompany(data))
                         //Chama serviço para atualizar lista de empresas, refatorar e fzer via redux
                        if(hasUpdated) {
                            hasUpdated();
                        }
                        if (isFirstCompany) {
                            dispatch(fetchCompanies())
                            await userAPI.updateToken();
                        }
                    }).catch((_) => {
                        HallAppToast.error();
                        setSuccess(false)
                    }).finally((_) => setIsLoading(false))
            }
        }
    }

    const zipcodeMask = useMask({ mask: '_____-___', replacement: { _: /\d/ } })

    useEffect(() => {
        if (zipcode.length === 9) {
            const searchAddressByZipcode = async () => {
                try {
                    setDisabledInputZipcode(true)
                    const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json`);
                    const data = response.data;
                    if (response.data.erro) {
                        setAddress(null)
                    } else {
                        const address = {
                            street: data.logradouro,
                            district: data.bairro,
                            state: data.uf,
                            city: data.localidade
                        }
                        setAddress(address)
                    }
                    setDisabledInputZipcode(false)
                } catch (error) {
                    setDisabledInputZipcode(false)
                    console.error('Erro ao buscar dados do CEP:', error);
                }
            };

            searchAddressByZipcode();
        }
    }, [zipcode]);


    return (
        <>
            {
                isSuccess ? <HallAppContainer>
                    <div style={{ textAlign: 'center', marginTop: '60px' }}>
                        <img src={success} style={{ width: '100px', marginTop: '50px', marginBottom: '30px' }} alt='logo' />

                        <h1 className='hallapp-title'>Parabéns!!!</h1>

                        <p className='hallapp-subtitle'>
                            Seu estabelecimento foi criado com sucesso. Agora, você pode começar a usufruir de várias funcionalidades
                            que estão disponíveis para você.
                        </p>

                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <AppLinkButton onClick={closeModal} type="submit" width='100%' name='fechar' />
                    </div>
                </HallAppContainer> :
                    <Formik
                        validationSchema={schema}
                        onSubmit={save}
                        initialValues={initialValues}
                        enableReinitialize={true}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                            <HallAppCenterContainer>
                                <Form noValidate onSubmit={handleSubmit}>
                                    <AppInput errors={errors}
                                        touched={touched}
                                        name='name'
                                        onChange={handleChange}
                                        value={values.name}
                                        type='text'
                                        label='Nome' />

                                    <AppInput
                                        errors={errors}
                                        touched={touched}
                                        name='unit'
                                        onChange={handleChange}
                                        value={values.unit}
                                        formText={`Caso tenha mais de 1 estabelecimento, informe o nome da unidade para que seus clientes consigam se identificar.`}
                                        type='text'
                                        label='Unidade' />

                                    <AppInput
                                        errors={errors}
                                        touched={touched}
                                        name='zipcode'
                                        onChange={(e) => {
                                            setFieldValue('zipcode', e.target.value);
                                            setZipcode(e.target.value);
                                        }}
                                        value={values.zipcode}
                                        type='text'
                                        label='CEP'
                                        disabled={disabledInputZipcode}
                                        mask={zipcodeMask} />

                                    {
                                        address && <div style={{ marginBottom: '20px' }}>
                                            <CustomCard>
                                                <p><span style={{ fontWeight: 'bold' }}>Rua: </span> {address?.street}</p>
                                                <p><span style={{ fontWeight: 'bold' }}>Bairro: </span> {address?.district}</p>
                                                <p><span style={{ fontWeight: 'bold' }}>Cidade/Estado: </span>{address?.city} - {address?.state}</p>
                                            </CustomCard>
                                        </div>
                                    }
                                    <AppInput errors={errors}
                                        touched={touched}
                                        name='number'
                                        onChange={handleChange}
                                        value={values.number}
                                        type='number'
                                        label='Número' />

                                    {isLoading ? <div style={{ textAlign: 'center' }}>
                                        <AppLoading />
                                    </div> :
                                        <AppButton type="submit" width='100%' name='salvar' />
                                    }
                                </Form>

                            </HallAppCenterContainer>
                        )}
                    </Formik>
            }
        </>
    );
}

export default CompanyForm;