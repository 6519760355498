import { useEffect, useState } from "react";
import FloatingButton from "../../../commons/buttons/FloatingButton";
import ModalCategory from "./ModalCategory";
import CategoryAPI from "../CategoryAPI";
import CompanyAPI from "../../company/CompanyAPI";
import { Col, Form, Row } from "react-bootstrap";
import HallAppContainer from "../../../commons/containers/HallAppContainer";
import AppLoading from "../../../commons/AppLoading";
import { normalizeString } from "../../../commons/util/HallAppUtil";
import { LeadingActions, SwipeableList, SwipeableListItem, SwipeAction, TrailingActions } from "react-swipeable-list";
import CustomCard from "../../../commons/cards/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import '../Categories.scss';
import ConfirmModal from "../../../commons/modal/ConfirmModal";
import HallAppToast from "../../../commons/HallAppToast";
import AppInput from "../../../commons/inputs/AppInput";
import PullToRefresh from "react-simple-pull-to-refresh";

function Categories() {
    const [showModalCategory, setShowModalCategory] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [categories, setCategories] = useState([])
    const [categoriesTmp, setCategoriesTmp] = useState([])
    const [categorySelected, setCategorySelected] = useState([])
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [isLoadingModalDelete, setIsLoadingModalDelete] = useState(false)

    useEffect(() => {
        findAllCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const categoryAPI = new CategoryAPI();
    const companyAPI = new CompanyAPI();

    const findAllCategories = async () => {
        try {
            setIsLoading(true)
            const response = await categoryAPI.findAllByCompanyId(companyAPI.getCompanyManagment().id)
            if (response.data) {
                response.data.sort((a, b) => a.name.localeCompare(b.name));
            }
            setCategories(response.data)
            setCategoriesTmp(response.data)
            setIsLoading(false)
        } catch (_) {
            setIsLoading(false)
        }
    };

    function search(value) {
        const normalizedValue = normalizeString(value);

        const results = categories.filter(service => {
            const normalizedName = normalizeString(service.name);
            const normalizedCategoryName = normalizeString(service.name);

            return normalizedName.includes(normalizedValue) || normalizedCategoryName.includes(normalizedValue);
        });

        setCategoriesTmp(results)
    }

    const editAction = (data) => (
        <LeadingActions>
            <SwipeAction onClick={() => {
                setCategorySelected(data)
                setShowModalCategory(true)
            }}>
                <div className="swipe-container-category">
                    <div className="swipe-edit">
                        <FontAwesomeIcon icon={faEdit} size="lg" />
                        <div>EDITAR</div>
                    </div>
                </div>
            </SwipeAction>
        </LeadingActions>
    );

    const deleteAction = (data) => (
        <TrailingActions>
            <SwipeAction
                destructive={false}
                onClick={() => {
                    setCategorySelected(data);
                    setShowModalDelete(true);
                }}
            >
                <div className="swipe-container-category">
                    <div className="swipe-delete">
                        <FontAwesomeIcon icon={faTrash} size="lg" />
                        <div>EXCLUIR</div>
                    </div>
                </div>
            </SwipeAction>
        </TrailingActions>
    );

    async function executeDeletion() {
        setIsLoadingModalDelete(true)
        try {
            await categoryAPI.delete(categorySelected.id);
            setIsLoadingModalDelete(false)
            setShowModalDelete(false)
            HallAppToast.success();
            findAllCategories()
        } catch (_) {
            HallAppToast.genericError();
        } finally {
            setIsLoadingModalDelete(false)
        }
    }


    return (
        <>
            <PullToRefresh onRefresh={findAllCategories}>
                <Row style={{ margin: 0, paddingTop: '20px' }}>
                    {isLoading ? (
                        <Col xs={12}>
                            <HallAppContainer>
                                <AppLoading />
                            </HallAppContainer>
                        </Col>
                    ) : (

                        <>
                            <Form>
                                <AppInput
                                    label="Pesquisar"
                                    name="search"
                                    errors={[]}
                                    onChange={(e) => search(e.target.value)} />
                            </Form>

                            <hr></hr>
                            {
                                categoriesTmp.map(data => (
                                    <Col xs={12} sm={12} md={4} lg={4} key={data.id} style={{ marginBottom: '10px' }}>
                                        <SwipeableList style={{ width: '100%' }}>
                                            <SwipeableListItem
                                                leadingActions={editAction(data)}
                                                trailingActions={deleteAction(data)}
                                                key={data.id}
                                                fullSwipe={false}
                                            >
                                                <div style={{ width: '100%' }}>
                                                    <CustomCard>
                                                        <div style={{
                                                            margin: 0, textAlign: 'center', fontWeight: 'bold'
                                                        }}>
                                                            {data.name}
                                                        </div>

                                                    </CustomCard>
                                                </div>
                                            </SwipeableListItem>
                                        </SwipeableList>
                                    </Col>

                                ))
                            }

                        </>
                    )}
                </Row>
            </PullToRefresh>
            <ConfirmModal show={showModalDelete}
                title='Excluir'
                isLoading={isLoadingModalDelete}
                confirm={executeDeletion}
                closeModal={() => {
                    setCategorySelected({});
                    setShowModalDelete(false)
                }}
            >
                Deseja remover a categoria <span style={{ fontWeight: 'bold' }}>{categorySelected.name}</span>?
                <br></br> <span style={{ fontWeight: 'bold' }}>Ao remover a categoria, todos os serviços vinculados a essa categoria serão removidos!</span>
            </ConfirmModal>
            <FloatingButton icon={faAdd} onClick={() => {
                setCategorySelected({});
                setShowModalCategory(true);
            }} />

            {
                showModalCategory && <ModalCategory
                    categorySelected={categorySelected}
                    findAllCategories={findAllCategories}
                    show={showModalCategory}
                    closeShow={() => {
                        setCategorySelected({});
                        setShowModalCategory(false);
                    }} />
            }

        </>
    );
}

export default Categories;