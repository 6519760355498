
import 'react-swipeable-list/dist/styles.css';
import '../Services.scss';

import FloatingButton from "../../../commons/buttons/FloatingButton";
import ModalServices from './ModalServices';
import { useEffect, useState } from 'react';
import AppLoading from '../../../commons/AppLoading';
import CategoryAPI from '../CategoryAPI';
import CompanyAPI from '../../company/CompanyAPI';
import CustomCard from '../../../commons/cards/Card';
import { formatInReal, normalizeString } from '../../../commons/util/HallAppUtil';
import { LeadingActions, SwipeAction, SwipeableList, SwipeableListItem, TrailingActions } from "react-swipeable-list";
import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Row } from "react-bootstrap";
import ServiceAPI from "../ServiceAPI";
import HallAppContainer from "../../../commons/containers/HallAppContainer";
import ConfirmModal from '../../../commons/modal/ConfirmModal';
import HallAppToast from '../../../commons/HallAppToast';
import AppInput from '../../../commons/inputs/AppInput';
import PullToRefresh from 'react-simple-pull-to-refresh';


function Services() {
    const [showModalNewService, setShowModalNewService] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [categories, setCategories] = useState([])
    const [services, setServices] = useState([])
    const [servicesTmp, setServicesTmp] = useState([])

    const [showModalDelete, setShowModalDelete] = useState(false)
    const [isLoadingModalDelete, setIsLoadingModalDelete] = useState(false)
    const [serviceSelected, setServiceSelected] = useState([])

    const categoryAPI = new CategoryAPI();
    const companyAPI = new CompanyAPI();
    const serviceAPI = new ServiceAPI();

    useEffect(() => {
        findAllServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const findAllServices = async () => {
        try {
            setIsLoading(true)
            const response = await serviceAPI.findAllByCompanyId(companyAPI.getCompanyManagment().id)
            if (response.data) {
                response.data.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
                response.data.forEach(element => {
                    element.color = getColorById(element.categoryName)
                });

            }
            setServices(response.data)
            setServicesTmp(response.data)
            setIsLoading(false)
        } catch (_) {
            setIsLoading(false)
        }
    };

    const editAction = (data) => (
        <LeadingActions>
            <SwipeAction onClick={() => {
                setServiceSelected(data);
                showModalFormService()
            }}>
                <div className="swipe-container">
                    <div className="swipe-edit">
                        <FontAwesomeIcon icon={faEdit} size="lg" />
                        <div>EDITAR</div>
                    </div>
                </div>
            </SwipeAction>
        </LeadingActions>
    );

    const deleteAction = (data) => (
        <TrailingActions>
            <SwipeAction
                destructive={false}
                onClick={() => {
                    setServiceSelected(data);
                    setShowModalDelete(true);
                }}
            >
                <div className="swipe-container">
                    <div className="swipe-delete">
                        <FontAwesomeIcon icon={faTrash} size="lg" />
                        <div>EXCLUIR</div>
                    </div>
                </div>
            </SwipeAction>
        </TrailingActions>
    );

    const idColorMap = {};

    // Função para gerar uma cor clara aleatória em formato hexadecimal
    function generateLightColor() {
        // Gera valores RGB entre 128 e 255 para garantir cores mais claras
        const r = Math.floor(Math.random() * 128 + 128);
        const g = Math.floor(Math.random() * 128 + 128);
        const b = Math.floor(Math.random() * 128 + 128);
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    }

    // Função que retorna a cor associada ao ID
    function getColorById(id) {
        // Se o ID já tem uma cor associada, retorna essa cor
        if (idColorMap[id]) {
            return idColorMap[id];
        } else {
            // Caso contrário, gera uma nova cor e associa ao ID
            const newColor = generateLightColor();
            idColorMap[id] = newColor;
            return newColor;
        }
    }

    async function executeDeletion() {
        setIsLoadingModalDelete(true)
        try {
            await serviceAPI.deleteByIdAndCompanyId(serviceSelected.id);
            setIsLoadingModalDelete(false)
            setShowModalDelete(false)
            HallAppToast.success();
            findAllServices()
        } catch (_) {
            HallAppToast.genericError();
            setIsLoadingModalDelete(false)
        } finally {
            setIsLoadingModalDelete(false)
        }
    }

    function search(value) {
        const normalizedValue = normalizeString(value);

        const results = services.filter(service => {
            const normalizedName = normalizeString(service.name);
            const normalizedCategoryName = normalizeString(service.categoryName);

            return normalizedName.includes(normalizedValue) || normalizedCategoryName.includes(normalizedValue);
        });

        setServicesTmp(results)
    }

    async function showModalFormService() {
        setIsLoading(true)
        setCategories([])
        const resp = await categoryAPI.findAllByCompanyId(companyAPI.getCompanyManagment().id)
        if (resp.data) {
            resp.data.sort((a, b) => a.name.localeCompare(b.name));
        }
        const options = resp.data.map(d => ({ value: d.id, label: d.name }));
        setCategories(options)
        setIsLoading(false)
        setShowModalNewService(true);
    }

    return (
        <>
         <PullToRefresh onRefresh={findAllServices}>
            <Row style={{ margin: 0, paddingTop: '20px' }}>
                {isLoading ? (
                    <Col xs={12}>
                        <HallAppContainer>
                            <AppLoading />
                        </HallAppContainer>
                    </Col>
                ) : (

                    <>
                        <Form>
                            <AppInput
                                label="Pesquisar"
                                name="search"
                                errors={[]}
                                onChange={(e) => search(e.target.value)} />
                        </Form>

                        <hr></hr>

                        {
                            servicesTmp.map(data => (
                                <Col xs={12} sm={12} md={4} lg={4} key={data.id} style={{ marginBottom: '10px' }}>
                                    <SwipeableList style={{ width: '100%' }}>
                                        <SwipeableListItem
                                            leadingActions={editAction(data)}
                                            trailingActions={deleteAction(data)}
                                            key={data.id}
                                            fullSwipe={false}
                                        >
                                            <div style={{ width: '100%' }}>
                                                <CustomCard>
                                                    <div style={{
                                                        backgroundColor: data?.color,
                                                        margin: 0, textAlign: 'center', fontWeight: 'bold'
                                                    }}>
                                                        {data.categoryName}
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <span style={{ fontWeight: 'bold' }}>Nome: </span>
                                                            <span>{data?.name}</span>
                                                        </div>
                                                        <div className="text-end">
                                                            <span style={{ fontWeight: 'bold' }}>a partir de: </span>
                                                            <span>{formatInReal(data?.value)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <span style={{ fontWeight: 'bold' }}>Duração: </span>
                                                            <span>{data?.duration} min.</span>
                                                        </div>
                                                        <div className="text-end">
                                                            <span style={{ fontWeight: 'bold' }}>Categoria: </span>
                                                            <span>{data.categoryName}</span>
                                                        </div>
                                                    </div>
                                                </CustomCard>
                                            </div>
                                        </SwipeableListItem>
                                    </SwipeableList>
                                </Col>

                            ))
                        }

                    </>
                )}
            </Row>
            </PullToRefresh>
            <ConfirmModal show={showModalDelete}
                title='Excluir'
                isLoading={isLoadingModalDelete}
                confirm={executeDeletion}
                closeModal={() => {
                    setServiceSelected({})
                    setShowModalDelete(false)
                }}
            >
                Deseja remover o serviço <span style={{ fontWeight: 'bold' }}>{serviceSelected.name} </span>
                da categoria <span style={{ fontWeight: 'bold' }}>{serviceSelected.categoryName}</span>?
            </ConfirmModal>
            <FloatingButton icon={faAdd} onClick={async () => {
                setServiceSelected({})
                showModalFormService()
            }} />

            {
                showModalNewService && <ModalServices
                    findAllServices={findAllServices}
                    options={categories}
                    show={showModalNewService}
                    serviceId={serviceSelected?.id || null}
                    closeShow={() => {
                        setShowModalNewService(false);
                        setServiceSelected({})
                    }} />
            }

        </>
    );
}

export default Services;