import React from 'react';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import './Input.scss';

const AppTextAreaInput = ({ type = 'text', label, value, onChange, name, errors, touched, mask, formText, disabled = false }) => {
    const inputClassName = `custom-input-focus custom-input ${disabled ? 'input-disabled' : ''}`;

    return (
        <>
            <FloatingLabel
                controlId={name}
                label={label}
                className="mb-3 custom-input-label"
            >
                <Form.Control name={name}
                    as="textarea"
                    style={{ height: '100px' }}
                    onChange={onChange}
                    value={value}
                    className={inputClassName}
                    type={type}
                    ref={mask}
                    disabled={disabled}
                    isInvalid={!!errors[name] && touched} />
                <Form.Control.Feedback style={{ textAlign: 'left' }} type="invalid">
                    {errors[name]}
                </Form.Control.Feedback>
                <Form.Text id="passwordHelpBlock" muted>
                    <p>{formText}</p>
                </Form.Text>
            </FloatingLabel>
        </>
    );
};

export default AppTextAreaInput;