import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import AppLoading from '../../../commons/AppLoading';
import HallAppCenterContainer from '../../../commons/containers/HallAppCenterContainer';
import '../Services.scss'

function AddCategory({ options, nextStep, serviceToEdit }) {

    useEffect(() => {
        if (serviceToEdit != null && Object.keys(serviceToEdit).length > 0) {
            const matchedOption = options.find(option => option.value === serviceToEdit.categoryId);
            setSelectedOption(matchedOption);
            setCategory(matchedOption)
            setShowNextButton(true)
        } else {
            setIsLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceToEdit]);


    const [isLoading, setIsLoading] = useState(false)
    const [isShowNextButton, setShowNextButton] = useState(false)
    const [category, setCategory] = useState(false)
    const [selectedOption, setSelectedOption] = useState(false)


    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 60, // Altere a altura conforme necessário
            minHeight: 60,
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: 10, // Ajuste o padding se necessário
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: 10,
        }),
        multiValue: (provided) => ({
            ...provided,
            height: 30, // Para multiselect, ajuste a altura
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999, // Garante que o menu fique acima de outros elementos
        }),
        // Você pode adicionar mais estilos conforme necessário
    };


    function changeOption(data) {
        setCategory(data)
        setSelectedOption(data)
        setShowNextButton(true)
    }

    return (
        <>
            {
                isLoading ? <HallAppCenterContainer><AppLoading /></HallAppCenterContainer> : <>
                    <Alert key='info' variant='info'>
                        <div style={{ textAlign: 'center' }}>
                            Selecione a categoria relacionado ao serviço, caso não encontre a categoria pode cadastrar uma, fechando a modal no
                            <span style={{ fontWeight: 'bold' }}> X</span> e indo na aba
                            <span style={{ fontWeight: 'bold' }}> Categorias. </span><br></br>
                            Após selecionar a categoria, clique em próximo
                        </div>
                    </Alert>
                    <Select menuPlacement="auto"
                        styles={customStyles}
                        options={options}
                        placeholder='Selecione a categoria'
                        onChange={(e) => changeOption(e)}
                        value={selectedOption} />


                    <div style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        border: 0,
                        borderRadius: 0,
                        width: '100%',
                    }}>
                        <Button onClick={() => {
                            if (nextStep) {
                                nextStep(category)
                            }
                        }} disabled={!isShowNextButton} className='btn-next-category' size="lg" style={{ borderRadius: '0', width: '100%', }}>
                            PRÓXIMO
                        </Button>
                    </div>
                </>
            }
        </>
    );
}

export default AddCategory;