import AppLinkButton from "../../../commons/buttons/AppLinkButton";
import HallAppContainer from "../../../commons/containers/HallAppContainer";
import success from '../../../assets/images/sucesso.png'

function SuccessServiceStep({ closeModal }) {
    return (
        <>
            <HallAppContainer>
                <div style={{ textAlign: 'center', marginTop: '60px' }}>
                    <img src={success} style={{ width: '100px', marginTop: '50px', marginBottom: '30px' }} alt='logo' />

                    <h1 className='hallapp-title'>Parabéns!!!</h1>

                    <p className='hallapp-subtitle'>
                        Seu estabelecimento foi salvo com sucesso. Agora, você pode começar a usufruir de várias funcionalidades
                        que estão disponíveis para você.
                    </p>

                </div>
                <div style={{ marginTop: '10px' }}>
                    <AppLinkButton onClick={closeModal} type="submit" width='100%' name='fechar' />
                </div>
            </HallAppContainer>
        </>
    );
}

export default SuccessServiceStep;