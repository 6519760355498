import { useEffect, useState } from "react";
import * as formik from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import AppInput from "../../../commons/inputs/AppInput";
import AppCurrencyInput from "../../../commons/inputs/AppCurrencyInput";
import { Button, ButtonGroup} from "react-bootstrap";
import '../Services.scss'

function AddService({ nextStep, backStep, category, serviceToEdit }) {
    const [initialValues, setInitialValues] = useState({
        name: '',
        value: '',
        duration: ''
    });

    const [value, setValue] = useState(0);

    useEffect(() => {
        if (serviceToEdit != null && Object.keys(serviceToEdit).length > 0) {
            setInitialValues({
                name: serviceToEdit.name,
                duration: serviceToEdit.duration,
                value: serviceToEdit.value
            })         
        }
    }, [serviceToEdit]);


    const { Formik } = formik;

    const schema = yup.object().shape({
        name: yup.string().required('Nome do serviço é obrigatório'),
        duration: yup.string().required('Duração do serviço é obrigatório'),
    });

    function createOrUpdate(values) {
        const data = {
            ...values,
            value: value,
            categoryId: category.value
        }
        if (nextStep) {
            nextStep(data)
        }
    }

    const handleChangeValue = (event, value, maskedValue) => {
        event.preventDefault();
        setValue(value)
      };

    return (
        <>
            <div style={{ textAlign: "center", marginBottom: '10px' }}>
                <span>
                    O serviço será criado para categoria:
                    <span style={{ fontWeight: "bold" }}> {category?.label}</span></span>
            </div>
            <Formik
                validationSchema={schema}
                onSubmit={createOrUpdate}
                enableReinitialize={true}
                initialValues={initialValues}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>

                        <AppInput
                            errors={errors}
                            touched={touched}
                            name='name'
                            onChange={handleChange}
                            value={values.name}
                            type='text'
                            label='Nome do serviço' />

                        <AppInput
                            errors={errors}
                            touched={touched}
                            name='duration'
                            onChange={handleChange}
                            value={values.duration}
                            type='number'
                            label='Duração - MEDIA EM MINUTOS' />


                        <AppCurrencyInput
                            errors={errors}
                            touched={touched}
                            name='value'
                            onChange={handleChangeValue}
                            value={values.value}
                            label='Valor - A PARTIR DE:'
                        />

                        <div style={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            border: 0,
                            borderRadius: 0,
                            width: '100%',
                        }}>
                           
                            <ButtonGroup style={{ borderRadius: '0', width: '100%', }}>
                                <Button onClick={backStep} variant="outline-dark" size="lg" style={{ borderRadius: '0', width: '100%', }}>
                                    VOLTAR
                                </Button>
                                <Button className="btn-next-category" type="submit" variant="dark" size="lg" style={{ borderRadius: '0', width: '100%', color: '#fff' }}>
                                    <span style={{ color: '#fff' }}>PRÓXIMO</span>
                                </Button>
                            </ButtonGroup>

                        </div>


                    </Form>
                )}
            </Formik>

        </>
    );
}

export default AddService;