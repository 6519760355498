import './Modal.scss'

import { Col, Row } from 'react-bootstrap';

import AppButton from '../buttons/AppButton';
import AppLinkButton from '../buttons/AppLinkButton';
import AppLoading from '../AppLoading';
import Modal from 'react-bootstrap/Modal';

function ConfirmModal({ show, closeModal, title, confirm, isLoading = false,children }) {

    return (
        <Modal className='hallapp-modal' centered show={show} fullscreen={false} onHide={() => closeModal()}>
            <Modal.Header style={{ border: 'none', padding: '25px' }} closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer style={{ border: 'none', padding: '25px' }}>
                {
                    isLoading ? <AppLoading /> : <Row className="justify-content-end">
                        <Col xs="auto">
                            <AppLinkButton name="não" onClick={() => closeModal()} />
                        </Col>
                        <Col xs="auto">
                            <AppButton name="sim" onClick={() => confirm()} />
                        </Col>
                    </Row>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmModal;