import './Splash.scss';

import AppName from '../../AppName';
import React from 'react';

const Splash = () => {
    return (
        <div className="splash-container">
            <div className="appname-container">
                <AppName />
            </div>
            {/* <div className="hallapp-text">HALLAPP</div> */}
        </div>
    );
};

export default Splash;