import './CreateAccout.scss';

import * as formik from 'formik';
import * as yup from 'yup';

import React, { useState } from 'react';

import AppButton from '../../commons/buttons/AppButton';
import AppInput from '../../commons/inputs/AppInput';
import AppLoading from '../../commons/AppLoading';
import CreateAccoutSuccess from './CreateAccountSuccess';
import Form from 'react-bootstrap/Form';
import HallAppConst from '../../commons/util/HallAppConst';
import HallAppToast from '../../commons/HallAppToast';
import { ToastContainer } from 'react-toastify';
import UserService from '../UserService';
import { useMask } from '@react-input/mask';
import { useNavigate } from 'react-router-dom';
import AuthContainer from '../AuthContainer';
import AppLinkButton from '../../commons/buttons/AppLinkButton';

const CreateAccout = () => {

    const [isLoading, setIsLoading] = useState(false)
    const { Formik } = formik;
    const [successCreate, setSuccessCreate] = useState(false)

    const service = new UserService();


    const phoneValidation = (value) => {
        const phoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/;
        return phoneRegex.test(value);
    };

    const nameValidation = (value) => {
        const nameParts = value.trim().split(' ');
        return nameParts.length >= 2;
    };

    const navigate = useNavigate();

    const login = () => {
        navigate('/login');
    };

    const schema = yup.object().shape({
        name: yup.string().required('Nome é obrigatório')
            .test('name-format', 'Por favor, informe o nome completo (nome e sobrenome)', nameValidation),
        email: yup.string().email('Email inválido').required('Email é obrigatório'),
        phone: yup.string().required('Telefone é obrigatório')
            .test('phone-format', 'Telefone deve ter exatamente 10 dígitos numéricos', phoneValidation),
        password: yup.string().required('Senha é obrigatório').min(6, 'Senha deve ter entre 6 á 10 caracteres')
            .max(10, 'Senha deve ter entre 6 á 10 caracteres'),
    });

    const createAccount = async (values, { setErrors, resetForm }) => {
        setIsLoading(true)

        service.createUser(values)
            .then((_) => {
                HallAppToast.success();
                resetForm()
                setSuccessCreate(true)
            }).catch((error) => {
                treatsErrors400(error.response, setErrors)
            }).finally((_) => setIsLoading(false))
    };

    const treatsErrors400 = (response, setErrors) => {
        if (response.status === 400) {
            HallAppToast.error(HallAppConst.ERROR_IN_FORM);
            if (response.data[0].code === 'USER_EMAIL_EXISTS') {
                setErrors({ email: 'O e-mail informado ja possui cadastro.' });
            }
        } else {
            HallAppToast.error(HallAppConst.GENERIC_ERROR);
        }
    }

    const phoneMask = useMask({ mask: '(__) _____-____', replacement: { _: /\d/ } });

    return (
        <AuthContainer>
            <div className="container container-wrapper">
                {
                    successCreate ? <CreateAccoutSuccess /> : <div className='container-create-account'>
                        <p className='login-txt-info hallapp-title'>Crie sua conta no HallApp</p>

                        <Formik
                            validationSchema={schema}
                            onSubmit={createAccount}
                            initialValues={{
                                name: '',
                                email: '',
                                phone: '',
                                password: '',
                            }}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <AppInput errors={errors}
                                        touched={touched}
                                        name='name'
                                        onChange={handleChange}
                                        value={values.name}
                                        type='text'
                                        label='Nome completo' />

                                    <AppInput
                                        errors={errors}
                                        touched={touched}
                                        name='email'
                                        onChange={handleChange}
                                        value={values.email}
                                        type='email'
                                        label='E-mail' />

                                    <AppInput
                                        errors={errors}
                                        touched={touched}
                                        name='phone'
                                        mask={phoneMask}
                                        onChange={handleChange}
                                        value={values.phone}
                                        type='text'
                                        label='WhatsApp' />

                                    <AppInput errors={errors}
                                        touched={touched}
                                        name='password'
                                        onChange={handleChange}
                                        value={values.password}
                                        type='password'
                                        label='Senha' />

                                    {isLoading ? <AppLoading /> :
                                        <AppButton type="submit" width='100%' name='criar conta' />
                                    }
                                </Form>
                            )}
                        </Formik>
                        <div style={{ marginTop: '10px' }}>
                            <AppLinkButton onClick={() => login()} type="submit" width='100%' name='VOLTAR' />
                        </div>
                        <ToastContainer />
                   

                    </div>
                }
            </div>
        </AuthContainer>

    );
};

export default CreateAccout;