import ApiService from "../../commons/services/ApiService";
import CompanyAPI from "../company/CompanyAPI";

const apiService = new ApiService();

export default class EmployeeAPI {

    URI_V1 = "/v1/employees"

    getProfits = async (filter: any) => {
        const companyAPI = new CompanyAPI();
        var query = `startDate=${filter.startDate}&endDate=${filter.endDate}&companyId=${companyAPI.getCompanyManagment().id}`;
        return await apiService.get(`${this.URI_V1}/${filter.employeeId}/profits?${query}`);
    };

  
}
