import React from 'react';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import './Input.scss';

const AppInputSelect = ({ label, onChange, name, formText, options = [], showOptionAll = false, value }) => {
    return (
        <>
            <FloatingLabel
                controlId={name}
                label={label}
                className="mb-3 custom-input-label"
            >
                <Form.Select
                    value={value}
                    name={name}
                    aria-label="Floating label select example"
                    onChange={onChange} >
                    {showOptionAll && <option value=''>Todos</option>}
                    {options.map((o) => <option value={o.value}>{o.label}</option>)}
                </Form.Select>

                <Form.Text id="passwordHelpBlock" muted>
                    <p>{formText}</p>
                </Form.Text>
            </FloatingLabel>
        </>
    );
};

export default AppInputSelect;