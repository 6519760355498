
import success from '../../assets/images/sucesso.png'
import errorIcon from '../../assets/images/error.png'
import { Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AppButton from '../../commons/buttons/AppButton';
import HallAppContainer from '../../commons/containers/HallAppContainer';
import { useEffect, useState } from 'react';
import AppLoading from '../../commons/AppLoading';
import UserService from '../UserService';
import AuthContainer from '../AuthContainer';

const api = new UserService();

const ActivateAccount = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isSuccess, setSuccess] = useState(true)


    const { token } = useParams();

    useEffect(() => {
        const active = async () => {
            try {
                await api.activateAccount(token)
                setSuccess(true)
                setIsLoading(false)
            } catch (_) {
                setSuccess(false)
                setIsLoading(false)
            }
        };

        active();
    }, [token]);

    const navigate = useNavigate();

    const toLogin = () => {
        navigate('/login');
    };

    return (
        <AuthContainer>
            <HallAppContainer>
                <div style={{ textAlign: 'center' }}>
                    {
                        isLoading ? <AppLoading /> :
                            isSuccess ?
                                <>
                                    < img src={success} style={{ width: '150px', marginTop: '50px' }} alt='logo' />
                                    <Row>
                                        <h1 style={{ marginTop: '20px', fontSize: '30px' }}>Parabéns!!!</h1>

                                        <span style={{ marginTop: '20px', fontSize: '18px' }}>
                                            Sua conta foi ativada, agora você já pode fazer o login.
                                        </span>
                                    </Row>

                                    <div style={{ marginTop: '20px' }}>
                                        <AppButton onClick={() => toLogin()} type="submit" width='100%' name='LOGIN' />
                                    </div>
                                </>
                                :
                                <>
                                    <img src={errorIcon} style={{ width: '150px', marginTop: '50px' }} alt='logo' />
                                    <Row>
                                        <h1 style={{ marginTop: '20px', fontSize: '30px' }}>Ooh Não!!!</h1>

                                        <span style={{ marginTop: '20px', fontSize: '18px' }}>
                                            Token expirado ou inválido, entre em contato com suporte HallApp.
                                        </span>
                                    </Row>

                                    <div style={{ marginTop: '20px' }}>
                                        <AppButton onClick={() => toLogin()} type="submit" width='100%' name='LOGIN' />
                                    </div>
                                </>

                    }
                </div>
            </HallAppContainer >
        </AuthContainer>
    );
};

export default ActivateAccount;