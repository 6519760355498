import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import './Services.scss';
import Categories from './components/Categories';
import Services from './components/Services';

function CategoriesAnServices() {
    const [showServices, setShowServices] = useState(false);

    const handleTabSelect = (key) => {
        setShowServices(key === "services");
    };

    return (
        <div className='div-custom-tabs no-space'>
            <Tabs
                defaultActiveKey="category"
                id="fill-tab-example"
                className="mb-3 custom-tabs"
                fill
                onSelect={handleTabSelect}
            >
                <Tab className='custom-tab' eventKey="category" title="Categorias">
                    <div className='container'  style={{ height: '100vh', overflowY: 'auto', paddingBottom: '220px' }}>
                        <Categories />
                    </div>
                </Tab>
                <Tab className='custom-tab' eventKey="services" title="Serviços">
                    <div className='container' style={{ height: '100vh', overflowY: 'auto', paddingBottom: '220px' }}>
                        {showServices && <Services />}
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}

export default CategoriesAnServices;