enum CommandStatus {
    WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
    PARTIAL_PAYMENT = 'PARTIAL_PAYMENT',
    PAID = 'PAID',
    UNKNOWN = 'UNKNOWN'
}

interface CommandStatusDTO {
    value: string;
    label: string;
}

export function getCommandStatus(): CommandStatusDTO[] {
    const types: CommandStatusDTO[] = [
        { value: CommandStatus.WAITING_FOR_PAYMENT, label: 'Aguardando Pagamento' },
        { value: CommandStatus.PARTIAL_PAYMENT, label: 'Pago Parcialmente' },
        { value: CommandStatus.PAID, label: 'Pago' }
    ];
    return types;
}