import { Col, Row } from "react-bootstrap";

import Placeholder from 'react-bootstrap/Placeholder';

function ReportValues({ isLoadingSummary, value, bgColor, label, textColor = "#fff" }) {

    return (
        <Row style={{ textAlign: 'center', marginTop: '10px' }}>
            <Col xs={12} sm={12} md={12} lg={12}>
                <div style={{ backgroundColor: bgColor, paddingTop: '20px', paddingBottom: '20px', borderRadius: '5px', color: "#fff" }}>
                    {
                        isLoadingSummary ? <Placeholder animation="glow">
                            <Placeholder xs={4} />
                        </Placeholder> : <span style={{ fontWeight: 'bold', fontSize: '30px', color: textColor }}>{value}</span>
                    }
                    <br></br>
                    <span style={{ color: textColor, fontSize: '13px' }}>{label}</span>
                </div>
            </Col>
        </Row>
    );
}

export default ReportValues;