import './Toast.scss'

import { toast } from "react-toastify";
import HallAppConst from './util/HallAppConst';

export default class HallAppToast {
   
    static error = (message: string) => {
        toast(message, {
            position: 'top-center',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: 'custom-toast-error',
            bodyClassName: 'custom-toast-body',
            progressClassName: 'custom-toast-progress'
        });
    };

    static success = () => {
        toast('Operação realizada com sucesso!', {
            position: 'top-center',
            autoClose: 5000, 
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: 'custom-toast-success',
            bodyClassName: 'custom-toast-body',
            progressClassName: 'custom-toast-progress'
        });
    };

    static genericError = () => {
        toast(HallAppConst.GENERIC_ERROR, {
            position: 'top-center',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: 'custom-toast-error',
            bodyClassName: 'custom-toast-body',
            progressClassName: 'custom-toast-progress'
        });
    };

}
