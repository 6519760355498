import { Badge, Row } from "react-bootstrap";

import AppLinkButton from "../../../commons/buttons/AppLinkButton";
import AppLoading from "../../../commons/AppLoading";
import CircularImage from "../../../commons/CircularImage";
import CustomCard from "../../../commons/cards/Card";

function EmployeeCard({ employee, resendEmail, isLoadingResendEmail }) {
    return (
        <div className="justify-content-center" style={{ alignItems: 'center', textAlign: 'center', float: 'center' }}>
            <CustomCard>
                {/*TODO:: Colocar imagem na pasta ao inves de puxar via url */}
                <CircularImage url="https://v0.betalabs.cloud/ecommerce/lifebike/img/unavailable.jpg" width={'120px'} />
                <Row>
                    <div style={{ marginTop: '15px' }}>
                        <p style={{ margin: '0' }}>{employee?.name}</p>
                        <p style={{ margin: '0' }}>{employee?.email}</p>
                        <p style={{ margin: '0' }}>
                            {
                                employee?.rules?.some(rule => rule.rule === "COMPANY" && rule.status === "ACTIVE") && <Badge bg="success">Administrador</Badge>
                            }
                            {
                                employee?.rules?.some(rule => rule.rule === "COMPANY" && rule.status === "INACTIVE") && <Badge bg="secondary">Administrador</Badge>
                            }
                        </p>
                        <p style={{ margin: '0' }}>
                            {
                                employee?.rules?.some(rule => rule.rule === "EMPLOYEE" && rule.status === "ACTIVE") && <Badge bg="primary">Funcionario</Badge>
                            }
                            {
                                employee?.rules?.some(rule => rule.rule === "EMPLOYEE" && rule.status === "INACTIVE") && <Badge bg="secondary">Funcionario</Badge>
                            }
                        </p>

                        {
                            employee?.rules?.some(rule => rule.status === "INACTIVE") &&
                            <div style={{ marginTop: '10px' }}>
                                {isLoadingResendEmail ?<AppLoading/> : <AppLinkButton width='100%' onClick={() => resendEmail(employee)} name='reenviar email' />}
                            </div>
                        }
                    </div>
                </Row>
            </CustomCard>
        </div>
    );
}

export default EmployeeCard;