import { formatDateInEN, formatInReal } from "../../commons/util/HallAppUtil";
import { useEffect, useState } from "react";
import ReportAPI from "./ReportAPI";
import ReportValues from "./components/ReportValues";
import HallAppToast from "../../commons/HallAppToast";
import ReportSummaryPayments from "./components/ReportSummaryPayments";
import FloatingButton from "../../commons/buttons/FloatingButton";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ReportModalFilter from "./components/ReportModalFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import HallAppCenterContainer from "../../commons/containers/HallAppCenterContainer";
import AppLoading from "../../commons/AppLoading";
import { Col, Row } from "react-bootstrap";
import CardCommand from "../commands/components/CardCommand";
import CommandAPI from "../commands/CommandAPI";
import EmployeeAPI from "../employee/EmployeeAPI";
import PullToRefresh from 'react-simple-pull-to-refresh';
import { fi } from "date-fns/locale";

function Report() {

    const [transactionSummary, setTranstactionSummary] = useState({})
    const [isLoadingSummary, setIsLoadingSummary] = useState(true)
    const [showModalFilter, setShowModalFilter] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [isLoadingPage, setIsLoadingPage] = useState(false)
    const [commands, setCommands] = useState([])
    const [totalResultRows, setTotalResultRows] = useState(0);
    const [isSearchEmployee, setIsSerachEmployee] = useState(false)
    const [profits, setProfits] = useState(0)
    const [employeeSearchName, setEmployeeSearchName] = useState('')

    const commandAPI = new CommandAPI();
    const employeeAPI = new EmployeeAPI();


    const [filter, setFilter] = useState({
        page: 0,
        limit: 50,
        status: '',
        startDate: formatDateInEN(new Date()),
        endDate: formatDateInEN(new Date()),
    })

    useEffect(() => {
        const filter = {
            startDate: formatDateInEN(new Date()),
            endDate: formatDateInEN(new Date()),
        }
        findReportSummary(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (filter.page === 0) {
            setCommands([])
            setTotalResultRows(0)
        }
        findAllCommands(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const findAllCommands = async () => {
        try {
            const response = await commandAPI.advancedSearch(filter)
            setCommands(prevItems => [...prevItems, ...response.data.content]);
            setTotalResultRows(response.data.totalResultRows)
        } catch (_) {
            setCommands([])
            setTotalResultRows(0)
        }
    };

    const reportAPI = new ReportAPI();

    async function findReportSummary(filter) {
        try {
            setIsLoadingSummary(true)
            const response = await reportAPI.getReportSummary(filter)
            setTranstactionSummary(response.data);
        } catch (_) {
            HallAppToast.genericError()
        } finally {
            setIsLoadingSummary(false)
        }
    }

    async function getFilter(data) {
        setShowModalFilter(false);
        findReportSummary(data);

        if (data.employeeId !== null) {
            setIsSerachEmployee(true)
            setEmployeeSearchName(data?.employeeName ?? '')
            await getProfits(data)
        } else {
            setIsSerachEmployee(false)
            setEmployeeSearchName(null)
        }

        setFilter({
            ...filter,
            page: 0,
            startDate: data.startDate,
            endDate: data.endDate,
            status: data.commandStatus,
            employeeId: data.employeeId,
            paymentType: data.paymentType
        })
    }

    async function getProfits(data) {
        try {
            const dataFilter = {
                startDate: data.startDate,
                endDate: data.endDate,
                employeeId: data.employeeId,
            };
            const response = await employeeAPI.getProfits(dataFilter)
            setProfits(response.data.value)
        } catch (_) {
            HallAppToast.error("Ocorreu um erro ao carregar seus lucros, tente novamente.")
        }
    };

    async function refresh() {
        findReportSummary({...filter, page: 0,});
    }

    return (
        <>
            <PullToRefresh onRefresh={refresh}>
                <ReportValues
                    isLoadingSummary={isLoadingSummary}
                    bgColor="#28a745"
                    value={formatInReal(transactionSummary?.totalReceived)}
                    label="total recebido" />

                <ReportValues
                    textColor="black"
                    isLoadingSummary={isLoadingSummary}
                    bgColor="#ffc107"
                    value={formatInReal(transactionSummary?.totalToReceive)}
                    label="total á receber" />

                <ReportValues
                    isLoadingSummary={isLoadingSummary}
                    bgColor="#17a2b8"
                    value={transactionSummary?.totalCustomer || 0}
                    label="cliente(s)" />

                {isSearchEmployee && <ReportValues
                    isLoadingSummary={isLoadingSummary}
                    bgColor="#4718ad"
                    value={formatInReal(profits) || 0}
                    label={`Comissão do Profissional - ${employeeSearchName}`} />}

                <hr></hr>
                <ReportSummaryPayments
                    transactionSummary={transactionSummary}
                    isLoadingSummary={isLoadingSummary} />
                <hr></hr>

                {isLoadingPage ? <HallAppCenterContainer><AppLoading /></HallAppCenterContainer> :
                    <InfiniteScroll
                        dataLength={commands.length}
                        key={commands.length}
                        next={() => {
                            if (commands.length < totalResultRows) {
                                setFilter({
                                    ...filter,
                                    page: filter.page + 1,
                                })
                            }
                        }}
                        hasMore={true}
                        loader={commands.length < totalResultRows && <HallAppCenterContainer><AppLoading /></HallAppCenterContainer>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! Você já viu de tudo</b>
                            </p>
                        }
                    >
                        <Row style={{ margin: 0 }}>
                            {
                                commands.map(data => (
                                    <Col xs={12} sm={12} md={4} lg={4} key={data.id} style={{ marginBottom: '10px' }}>
                                        <CardCommand updateCommands={() => {
                                            setFilter({
                                                ...filter,
                                                page: 0,
                                            })
                                        }} command={data} />
                                    </Col>

                                ))
                            }
                        </Row>
                    </InfiniteScroll>}

                <FloatingButton icon={faSearch} onClick={() => {
                    setShowModalFilter(true);
                }} />

                <ReportModalFilter
                    getFilter={getFilter}
                    show={showModalFilter}
                    closeShow={() => {
                        setShowModalFilter(false);
                    }} />
            </PullToRefresh>
        </>
    );
}

export default Report;