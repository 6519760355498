import Button from 'react-bootstrap/Button';
import React from 'react';

interface AppButtonProps {
    name: string;
    width?:string;
    type?:String;
    onClick: Function;
  }

const AppButton: React.FC<AppButtonProps> = ({ name, width, type, onClick, disabled = false }) => {
  return (
    <Button disabled={disabled} onClick={onClick} className='hallapp-btn-primary' type={type} style={{width: width, height: '50px'}} variant="dark">{name.toUpperCase()}</Button>
    );
};

export default AppButton;