import React, { useState } from 'react';

import AppInput from '../../commons/inputs/AppInput';
import AppLinkButton from '../../commons/buttons/AppLinkButton';
import AppButton from '../../commons/buttons/AppButton';
import { useNavigate, useParams } from 'react-router-dom';
import * as formik from 'formik';
import * as yup from 'yup';
import { Form, Row } from 'react-bootstrap';
import AppLoading from '../../commons/AppLoading';
import UserService from '../UserService';
import HallAppToast from '../../commons/HallAppToast';
import { ToastContainer } from 'react-toastify';
import AppName from '../../commons/AppName';
import success from '../../assets/images/password-success.png'
import HallAppCenterContainer from '../../commons/containers/HallAppCenterContainer';
import AuthContainer from '../AuthContainer';



const UpadatePassword = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setSuccess] = useState(false)

    const { Formik } = formik;
    const { token } = useParams();

    const service = new UserService();

    const navigate = useNavigate();

    const toLogin = () => {
        navigate('/login');
    };



    const schema = yup.object().shape({
        password: yup.string().min(6, 'Senha deve ter entre 6 á 10 caracteres')
            .max(10, 'Senha deve ter entre 6 á 10 caracteres')
            .required('Senha é obrigatória'),
    });

    const recoveryPassword = async (values) => {
        setIsLoading(true)

        service.updatePassword(values, token)
            .then((_) => {
                setSuccess(true)
            }).catch((_) => {
                HallAppToast.error('Token expirado, solicite uma nova redefinição.');
            }).finally((_) => setIsLoading(false))
    };

    return (
        <AuthContainer>
            <div className="container container-wrapper">
                {
                    !isSuccess ? <div className='container-login'>
                        <AppName />

                        <Formik
                            validationSchema={schema}
                            onSubmit={recoveryPassword}
                            initialValues={{
                                email: '',
                            }}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <p className='login-txt-info hallapp-title'>Criar nova senha</p>
                                    <p className='hallapp-subtitle'>Informe a nova senha</p>
                                    <AppInput
                                        errors={errors}
                                        touched={touched}
                                        name='password'
                                        onChange={handleChange}
                                        value={values.password}
                                        type='password'
                                        label='Senha' />


                                    {isLoading ? <AppLoading /> :
                                        <>
                                            <AppButton type="submit" width='100%' name='salvar' />
                                            <div style={{ marginTop: '10px' }}>
                                                <AppLinkButton onClick={() => toLogin()} type="submit" width='100%' name='VOLTAR' />
                                            </div>
                                        </>

                                    }
                                </Form>
                            )}
                        </Formik>
                        <ToastContainer />
                    </div> : <>

                        <HallAppCenterContainer>
                            <div style={{ textAlign: 'center' }}>
                                <img src={success} style={{ width: '200px', marginTop: '50px' }} alt='logo' />
                                <Row>
                                    <h1 style={{ marginTop: '20px', fontSize: '30px' }}>Parabéns!!!</h1>

                                    <span style={{ marginTop: '20px', fontSize: '18px' }}>
                                        Senha alterada com sucesso.
                                    </span>
                                </Row>

                                <div style={{ marginTop: '20px' }}>
                                    <AppButton onClick={() => toLogin()} type="submit" width='100%' name='LOGIN' />
                                </div>
                            </div>
                        </HallAppCenterContainer>
                    </>
                }
            </div>
        </AuthContainer>
    );
};

export default UpadatePassword;