import React from 'react';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import './Input.scss';

import IntlCurrencyInput from "react-intl-currency-input";

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

const AppCurrencyInput = ({ label, value, onChange, name, errors, disabled = false }) => {
    const inputClassName = `custom-input-focus form-control custom-input ${disabled ? 'input-disabled' : ''}`;

    return (
        <>
            <FloatingLabel
                controlId={name}
                label={label}
                className="mb-3 custom-input-label"
            >
                <IntlCurrencyInput 
                    currency="BRL"
                    config={currencyConfig}
                    className={inputClassName}
                    value={value}
                    onChange={(event, value, maskedValue) => onChange(event, value, maskedValue)}
                />
                <Form.Control.Feedback style={{ textAlign: 'left' }} type="invalid">
                    {errors && errors[name]}
                </Form.Control.Feedback>            
            </FloatingLabel>
        </>
    );
};

export default AppCurrencyInput;