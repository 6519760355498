import './CustomContainer.scss';

function CustomContainer({ children }) {
    return (
        <div className='custom-container-hallapp-wrapper'>
            <div className='custom-container-hallapp'>
                {children}
            </div>
        </div>
    );
}

export default CustomContainer;