import Form from 'react-bootstrap/Form';

function Switch({ label, checked, onChange }) {
    return (
        <Form>
            <Form.Check
                checked={checked}
                type="switch"
                id="custom-switch"
                label={label}
                onChange={onChange}
            />
        </Form>
    );
}

export default Switch;