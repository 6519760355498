import ApiService from "../../commons/services/ApiService";

const apiService = new ApiService();

export default class CategoryAPI {

    URI_V1 = "/v1/categories"

    create = async (data: any) => {
        return await apiService.post(`${this.URI_V1}`, data);
    };

    update = async (categoryId: any, data: any) => {
        return await apiService.put(`${this.URI_V1}/${categoryId}`, data);
    };

    delete = async (categoryId: any) => {
        return await apiService.delete(`${this.URI_V1}/${categoryId}`);
    };

    findAllByCompanyId = async (companyId: any) => {
        return await apiService.get(`${this.URI_V1}/company/${companyId}`);
    };

}
