import { format } from "date-fns";
import pt from 'date-fns/locale/pt-BR';

export const isNullOrEmpty = (value) => {
  return value === null || value === undefined || value === "";
}

export function formatInReal(numero) {
  if (isNaN(numero)) numero = 0;
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(numero);
}

export function normalizeString(str) {
  return str
    .toLowerCase()  // Converte para minúsculas
    .normalize('NFD')  // Normaliza a string para permitir a remoção de acentos
    .replace(/[\u0300-\u036f]/g, '');  // Remove os acentos
}

export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adiciona 1 ao mês e garante dois dígitos
  const day = String(date.getDate()).padStart(2, '0'); // Garante dois dígitos

  return `${year}-${month}-${day}`;
}

export function formatCurrencyInputBR(value) {
  return value.toString()
    .replace('R$', '')
    .replace('.', '')
    .replace(',', '.')
}

export function formatDateTime(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Janeiro é 0
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export function formatDate(dateString) {
  const [year, month, day] = dateString.split('-'); // Divide a string diretamente
  return `${day}/${month}/${year}`; // Retorna no formato desejado
}

export function formatDateInEN(date) {
  if (isNaN(date)) return null;
  return format(date, 'yyyy-MM-dd', { locale: pt })
}

export function formatTransactionType(type) {
  switch (type) {
    case 'CREDIT_CARD':
      return 'Crédito';
    case 'DEBIT_CARD':
      return 'Debito';
    case 'PIX':
      return 'PIX';
    case 'MONEY':
      return 'Dinheiro';
    default:
      return 'Tipo desconhecido';
  }
}

export function getCurrentTimestamp() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const second = String(now.getSeconds()).padStart(2, '0');
  const millisecond = String(now.getMilliseconds()).padStart(3, '0');
  
  const nanosecondPart = (performance.now() % 1).toFixed(9).slice(2); 

  return `${year}${month}${day}${hour}${minute}${second}${millisecond}${nanosecondPart}`;
}