import ApiService from "../../commons/services/ApiService";

const apiService = new ApiService();

export default class CompanyAPI {

    URI_V1 = "/v1/companies"

    createCompany = async (step: string, data: any) => {
        return await apiService.post(`${this.URI_V1}?step=${step}`, data);
    };

    updateCompany = async (id: number, data: any) => {
        return await apiService.put(`${this.URI_V1}/${id}`, data);
    };

    getEstablishments = async () => {
        return await apiService.get(`${this.URI_V1}`);
    };

    deleteEstablishments = async (companyId: number) => {
        return await apiService.delete(`${this.URI_V1}/${companyId}`);
    };

    addEmployee = async (companyId: number, data: any) => {
        return await apiService.post(`${this.URI_V1}/${companyId}/employee`, data);
    };

    getAllEmployee = async (companyId: number) => {
        return await apiService.get(`${this.URI_V1}/${companyId}/employees`);
    };

    resendEmailEmployee = async (companyId: number, data: any) => {
        return await apiService.post(`${this.URI_V1}/${companyId}/employee/resend-email`, data);
    };

    acceptedInvite = async (token: string) => {
        return await apiService.patch(`${this.URI_V1}/employee/accepted-invite?token=${token}`, null);
    };

    updateEmployee = async (companyId: number, data: any) => {
        return await apiService.put(`${this.URI_V1}/${companyId}/employee`, data);
    };

    deleteEmployee = async (companyId: number, employeeId: number) => {
        return await apiService.delete(`${this.URI_V1}/${companyId}/employee/${employeeId}`);
    };

    findAllMe = async () => {
        return await apiService.get(`${this.URI_V1}/me`);
    };

    getCompanyManagment() {
        const c = localStorage.getItem('company-management')
        if (c === null) return null;
        return JSON.parse(c);
    }

    setCompanyManagment(company: any) {
        localStorage.setItem('company-management', JSON.stringify(company))
    }

    setCompaniesLocalStorage(companies: []) {
        localStorage.setItem('companies', JSON.stringify(companies))
    }

}
