enum PaymentType {
    CREDIT_CARD = 'CREDIT_CARD',
    DEBIT_CARD = 'DEBIT_CARD',
    PIX = 'PIX',
    MONEY = 'MONEY',
    UNKNOWN = 'UNKNOWN'
}

interface PaymentTypeDTO {
    value: string;
    label: string;
}

export function getPaymentTypes(): PaymentTypeDTO[] {
    const types: PaymentTypeDTO[] = [
        { value: PaymentType.CREDIT_CARD, label: 'Crédito' },
        { value: PaymentType.DEBIT_CARD, label: 'Debito' },
        { value: PaymentType.PIX, label: 'PIX' },
        { value: PaymentType.MONEY, label: 'Dinheiro' }
    ];
    return types;
}