import './Commands.scss'

import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { formatDateInEN, formatInReal } from "../../commons/util/HallAppUtil";

import AppLoading from "../../commons/AppLoading";
import CardCommand from "./components/CardCommand";
import CommandAPI from "./CommandAPI";
import CommandFilter from './components/CommandFIlter';
import EmployeeAPI from '../employee/EmployeeAPI';
import FloatingButton from "../../commons/buttons/FloatingButton";
import HallAppCenterContainer from "../../commons/containers/HallAppCenterContainer";
import HallAppToast from '../../commons/HallAppToast';
import InfiniteScroll from "react-infinite-scroll-component";
import ModalCommand from "./components/Command";
import NotFoundResult from '../../commons/NotFoundResult';
import UserService from '../../auth/UserService';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

function Commands() {
  const [showModalCommand, setShowModalCommand] = useState(false)

  const [commands, setCommands] = useState([])
  const [totalResultRows, setTotalResultRows] = useState(0);

  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [isShowNotFoundPage, setShowNotfoundPage] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [isLoadingProfits, setIsLoadingProfits] = useState(true)
  const [profits, setProfits] = useState(0)

  const userAPI = new UserService();
  const employeeAPI = new EmployeeAPI();

  const [filter, setFilter] = useState({
    page: 0,
    limit: 50,
    status: '',
    employeeId: userAPI.getUserData().id,
    startDate: formatDateInEN(new Date()),
    endDate: formatDateInEN(new Date()),
  })

  const commandAPI = new CommandAPI();

  const findAllCommands = async () => {
    try {
      const response = await commandAPI.advancedSearch(filter)
      setCommands(prevItems => [...prevItems, ...response.data.content]);
      setTotalResultRows(response.data.totalResultRows)
      setShowNotfoundPage(false)
    } catch (_) {
      setCommands([])
      setTotalResultRows(0)
      setShowNotfoundPage(true)
    }
  };

  const getProfits = async () => {
    try {
      setIsLoadingProfits(true)
      const response = await employeeAPI.getProfits(filter)
      setProfits(response.data.value)
    } catch (_) {
      HallAppToast.error("Ocorreu um erro ao carregar seus lucros, tente novamente.")
    } finally {
      setIsLoadingProfits(false)
    }
  };

  function changeStatus(value) {
    setFilter({
      ...filter,
      page: 0,
      status: value === 'ALL' ? null : value
    })
  }

  function changeDates(startDate, endDate) {
    if (endDate === null) {
      endDate = startDate;
    }
    setFilter({
      ...filter,
      page: 0,
      startDate: startDate,
      endDate: endDate
    })
  }

  useEffect(() => {
    if (filter.page === 0) {
      setCommands([])
      setTotalResultRows(0)
    }
    findAllCommands(filter);
    getProfits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <div className='my-wallet-command'>
        <span style={{ fontSize: '30px', marginTop: '60px' }}>{formatInReal(profits)}</span>
        <span style={{ fontSize: '13px' }}>meus ganhos</span>
      </div>
      <div style={{ margin: 0, paddingTop: '100px', display: showModalCommand ? 'none' : 'block' }} className='filter-my-command'>
        <CommandFilter changeStatus={changeStatus} changeDates={changeDates} />
      </div>

      {isShowNotFoundPage && <HallAppCenterContainer><NotFoundResult /></HallAppCenterContainer>}

      {isLoadingPage ? <HallAppCenterContainer><AppLoading /></HallAppCenterContainer> :
        <InfiniteScroll
        
          dataLength={commands.length}
          key={commands.length}
          next={() => {
            if (commands.length < totalResultRows) {
              setFilter({
                ...filter,
                page: filter.page + 1,
              })
            }
          }}
          hasMore={true}
          loader={commands.length < totalResultRows && <HallAppCenterContainer><AppLoading /></HallAppCenterContainer>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! Você já viu de tudo</b>
            </p>
          }
        >
          <Row style={{ margin: 0, paddingTop: '70px' }}>
            {
              commands.map(data => (
                <Col xs={12} sm={12} md={4} lg={4} key={data.id} style={{ marginBottom: '10px' }}>
                  <CardCommand key={data.id} updateCommands={() => {
                    setFilter({
                      ...filter,
                      page: 0,
                    })
                  }}
                    command={data} />
                </Col>

              ))
            }
          </Row>
        </InfiniteScroll>}

      <FloatingButton icon={faAdd} onClick={() => {
        setShowModalCommand(true);
      }} />
      {
        showModalCommand && <ModalCommand
          show={showModalCommand}
          findAllCommands={async () => {
            setIsLoadingPage(true)
            setFilter({
              ...filter,
              page: 0,
            })
            setIsLoadingPage(false)
          }}
          closeShow={() => {
            setShowModalCommand(false);
          }} />
      }

    </>
  );
}

export default Commands;