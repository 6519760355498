import { combineReducers } from 'redux';
import { COMPANIES, SELECTED_COMPANY } from '../actions/companyAction';

const initialState = {
  companies: []
};

const companyReducer = (state = initialState, action) => { //TODO:: jogar pra outra classe javascript
  switch (action.type) {
    case SELECTED_COMPANY:
      return { ...state, companyManagement: action.payload };
    case COMPANIES:
        return { ...state, companies: action.payload };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  company: companyReducer
});

export default rootReducer;