import ApiService from "../../commons/services/ApiService";
import CompanyAPI from "../company/CompanyAPI";

const apiService = new ApiService();

export default class ReportAPI {

    URI_V1 = "/v1/reports"

    getReportSummary = async (filter: any) => {
        const companyAPI = new CompanyAPI();
        var query = `startDate=${filter.startDate}&endDate=${filter.endDate}`;
        if (filter.commandStatus) {
            query += `&commandStatus=${filter.commandStatus}`
        }

        if (filter.employeeId) {
            query += `&employeeId=${filter.employeeId}`
        }

        if (filter.paymentType) {
            query += `&paymentType=${filter.paymentType}`
        }

        return await apiService.get(`${this.URI_V1}/company/${companyAPI.getCompanyManagment().id}/summary?${query}`);
    };

  
}
