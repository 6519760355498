import { Modal } from "react-bootstrap";
import CalendarInput from "../../../commons/inputs/CalendarInput";
import '../Report.scss'
import AppInputSelect from "../../../commons/inputs/AppInputSelect";
import { getPaymentTypes } from "../../../commons/enums/PaymentType";
import { getCommandStatus } from "../../../commons/enums/CommandStatus";
import { useEffect, useState } from "react";
import Select from 'react-select'
import CompanyAPI from "../../company/CompanyAPI";
import AppButton from "../../../commons/buttons/AppButton";
import { formatDateInEN } from "../../../commons/util/HallAppUtil";
import AppLinkButton from "../../../commons/buttons/AppLinkButton";

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: 60,
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};
function ReportModalFilter({ closeShow, show, getFilter }) {
    const [employees, setEmployees] = useState([])
    const [dates, setDates] = useState([new Date(), new Date()]);

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [paymentType, setPaymentType] = useState(null)
    const [commandStatus, setCommandStatus] = useState(null)
    const [employeeId, setEmployeeId] = useState(null);
    const [employee, setEmployee] = useState(null);

    const companyAPI = new CompanyAPI();

    const getAllEmployee = async () => {
        try {
            const response = await companyAPI.getAllEmployee(companyAPI.getCompanyManagment().id);
            const optionAll = { value: '', label: 'Todos' }
            const collectOptions = [];
            collectOptions.push(optionAll)
            response.data.forEach(element => {
                collectOptions.push({ value: element.id, label: element.name || element.email })
            });
            setEmployees(collectOptions)
        } catch (_) {
            setEmployees([])
        }
    };

    useEffect(() => {
        getAllEmployee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function changeDates(startDate, endDate) {
        setStartDate(startDate)
        setEndDate(endDate)
    }

    function changeEmployee(selected) {
        setEmployee(selected)
        setEmployeeId(selected?.value);
    }

    function search() {
        const data = {
            startDate: startDate || formatDateInEN(new Date()),
            endDate: endDate || formatDateInEN(new Date()),
            commandStatus: commandStatus,
            paymentType: paymentType,
            employeeId: employeeId,
            employeeName: employee?.label
        }
        getFilter(data);
    }

    function clearFilter() {
        changeEmployee(null)
        setCommandStatus({ value: '', label: 'Todos' })
        setPaymentType({ value: '', label: 'Todos' })
        setDates([new Date(), new Date()])
        const data = {
            startDate: formatDateInEN(new Date()),
            endDate: formatDateInEN(new Date()),
            commandStatus: null,
            paymentType: null,
            employeeId: null
        }
        getFilter(data);
    }

    return (
        <>
            <Modal show={show} fullscreen={true} onHide={() => closeShow()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontSize: '15px' }}>
                        FILTRO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ marginBottom: '20px' }}>
                        <CalendarInput
                            setDates={setDates}
                            changeDates={changeDates}
                            values={dates} />
                    </div>

                    <AppInputSelect
                        value={commandStatus}
                        onChange={(e) => setCommandStatus(e.target.value)}
                        label='Status'
                        options={getCommandStatus()}
                        showOptionAll={true} />

                    <AppInputSelect
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                        label='Tipo Pagamento'
                        options={getPaymentTypes()}
                        showOptionAll={true} />

                    <Select
                        value={employee}
                        menuPlacement="auto"
                        styles={customStyles}
                        options={employees}
                        placeholder='Funcionario'
                        onChange={(e) => changeEmployee(e)}
                    />

                    <hr></hr>
                    <AppButton width='100%' name='pesquisar' onClick={search} />
                    <div style={{ marginTop: '20px' }}>
                        <AppLinkButton width='100%' name='limpar filtro' onClick={clearFilter} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ReportModalFilter;