import CompanyAPI from "../../pages/company/CompanyAPI";
import { isNullOrEmpty } from "../../commons/util/HallAppUtil";

export const SELECTED_COMPANY = 'SELECTED_COMPANY';
export const COMPANIES = 'COMPANIES';

export const COMPANY_MANAGEMENT = 'company-management';

export const setCompanies = (companies) => ({
  type: COMPANIES,
  payload: companies
});

export const selectedCompany = (company) => {
  return async (dispatch) => {
    try {
      localStorage.setItem(COMPANY_MANAGEMENT, JSON.stringify(company))
      dispatch({
        type: SELECTED_COMPANY,
        payload: company
      });
    } catch (_) {
      dispatch({
        type: SELECTED_COMPANY,
        payload: company
      });
    }
  } 
}

export const fetchCompanies = () => {
  return async (dispatch) => {
    try {
      const companyService = new CompanyAPI();
      const response = await companyService.getEstablishments();
      if (response.data.length === 0) return;
      const companies = response.data;
      localStorage.setItem('companies', JSON.stringify(companies))
      const c = localStorage.getItem(COMPANY_MANAGEMENT)
      if (isNullOrEmpty(c)) {
        const company = companies[0]
        localStorage.setItem(COMPANY_MANAGEMENT, JSON.stringify(company))
        dispatch(selectedCompany(company));
      } else {
        dispatch(selectedCompany(JSON.parse(c)));
      }

      dispatch(setCompanies(companies));
    } catch (_) {
      dispatch(setCompanies([]));
    }
  }
}