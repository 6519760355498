import Button from 'react-bootstrap/Button';
import React from 'react';

import './Buttons.scss';

interface AppLinkButtonProps {
    name: string;
    onClick: Function;
  }

const AppLinkButton: React.FC<AppLinkButtonProps> = ({ name, onClick, width }) => {
  return (
    <Button className='text-color link-button' style={{height: '50px', width: width}}  onClick={onClick} variant="outline-dark">{name.toUpperCase()}</Button>
  );
};

export default AppLinkButton;