import ApiService from "../../commons/services/ApiService";
import CompanyAPI from "../company/CompanyAPI";

const apiService = new ApiService();

export default class CommandAPI {

    URI_V1 = "/v1/commands"

    createCommand = async (data: any) => {
        return await apiService.post(`${this.URI_V1}`, data);
    };

    updateCommand = async (id: any, data: any) => {
        return await apiService.put(`${this.URI_V1}/${id}`, data);
    };

    advancedSearch = async (filter: any) => {
        var query = `page=${filter.page}&limit=${filter.limit}&companyId=${new CompanyAPI().getCompanyManagment().id}`;
        if (filter.status) {
            query += `&status=${filter.status}`
        }

        if (filter.startDate) {
            query += `&startDate=${filter.startDate}&endDate=${filter.endDate}`
        }

        if (filter.employeeId) {
            query += `&employeeId=${filter.employeeId}`
        }

        if (filter.paymentType) {
            query += `&paymentType=${filter.paymentType}`
        }

        return await apiService.get(`${this.URI_V1}/advanced-search?${query}`);
    };

    delete = async (commandId: any, companyId: any) => {
        return await apiService.delete(`${this.URI_V1}/${commandId}?companyId=${companyId}`);
    };

    createTransaction = async (commandId: any, data: any) => {
        return await apiService.post(`${this.URI_V1}/${commandId}/transaction`, data);
    };

    getTransactions = async (commandId: any) => {
        return await apiService.get(`${this.URI_V1}/${commandId}/transactions`);
    };

    deleteTransaction = async (commandId: any, transactionId: any) => {
        return await apiService.delete(`${this.URI_V1}/${commandId}/transaction/${transactionId}`);
    };
}
