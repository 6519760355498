import { ListGroup } from "react-bootstrap";

import Placeholder from 'react-bootstrap/Placeholder';
import { formatInReal, formatTransactionType } from "../../../commons/util/HallAppUtil";

function ReportSummaryPayments({ isLoadingSummary, transactionSummary }) {

    return (
        <ListGroup>
            {
                isLoadingSummary ?
                    <>          
                        <Placeholder xs={12} bg="secondary" />
                        <Placeholder xs={12} bg="secondary" />

                    </>
                    :
                    transactionSummary?.received?.map((t) =>
                        <ListGroup.Item>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>{formatTransactionType(t.type)}</strong>
                                </div>
                                <div>
                                    {formatInReal(t.value)}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <strong>Quantidade</strong>
                                </div>
                                <div>
                                    {t.quantity}
                                </div>
                            </div>
                        </ListGroup.Item>
                    )

            }
        </ListGroup>
    );
}

export default ReportSummaryPayments;