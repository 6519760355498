import { useEffect, useState } from "react";
import AddCategory from "./AddCategory";
import { Modal } from "react-bootstrap";
import AddService from "./AddService";
import AddEmployee from "./AddEmployee";
import SuccessServiceStep from "./SuccessServiceStep";
import ServiceAPI from "../ServiceAPI";
import AppLoading from "../../../commons/AppLoading";
import HallAppCenterContainer from "../../../commons/containers/HallAppCenterContainer";


function ModalServices({ closeShow, show, options, findAllServices, serviceId }) {

    const [step, setStep] = useState(1)
    const [category, setCategory] = useState({})
    const [service, setService] = useState({})
    const [isLoading, setIsLoading] = useState({})
    const [serviceToEdit, setServiceToEdit] = useState({})

    useEffect(() => {
        if (serviceId != null) {
            findServiceById()
        } else {
            setIsLoading(false)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceId]);

    const serviceAPI = new ServiceAPI();
    async function findServiceById() {
        try {
            setIsLoading(true)
            const response = await serviceAPI.findById(serviceId);
            setServiceToEdit(response.data)
            setIsLoading(false)
        } catch (_) {
            closeModal()
        }
    }

    function nextStep(data) {
        if (step === 1) {
            setCategory(data)
        }
        if (step === 2) {
            setService(data)
        }
        setStep(step + 1)
    }

    function backStep() {
        setStep(step - 1)
    }

    function closeModal() {
        if (step === 4) {
            findAllServices()
        }
        setServiceToEdit({})
        setCategory({})
        setService({})
        setStep(1)
        closeShow()
    }

    return (
        <>
            <Modal className='hallapp-establishment-modal' show={show} fullscreen={true} onHide={() => closeModal()}>
                <Modal.Header className='hallapp-establishment-modal custom-close-button' closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontSize: '15px' }}>
                        ADICIONAR SERVIÇO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='hallapp-establishment-modal'>
                    {
                        isLoading ? <HallAppCenterContainer><AppLoading /></HallAppCenterContainer> : <>
                            {step === 1 && <AddCategory
                                serviceToEdit={serviceToEdit}
                                options={options}
                                nextStep={nextStep}
                                category={category} />}

                            {step === 2 && <AddService nextStep={nextStep}
                                category={category}
                                backStep={backStep}
                                serviceToEdit={serviceToEdit}
                            />}
                            {step === 3 && <AddEmployee
                                findAllServices={findAllServices}
                                service={service}
                                nextStep={nextStep}
                                backStep={backStep} 
                                serviceToEdit={serviceToEdit}/>}
                            {step === 4 && <SuccessServiceStep closeModal={closeModal} />}
                        </>
                    }

                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalServices;