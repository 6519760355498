import CompanyForm from "./CompanyForm";
import { Modal } from "react-bootstrap";

function ModalCompanyEditForm({ closeShow, show, hasUpdated, company }) {
    return (
        <>
            <Modal className='hallapp-establishment-modal' show={show} fullscreen={true} onHide={() => closeShow()}>
                <Modal.Header className='hallapp-establishment-modal custom-close-button' closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontSize: '15px' }}>
                        ALTERAR ESTABELECIMENTO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='hallapp-establishment-modal'>
                    <CompanyForm company={company} closeModal={closeShow} hasUpdated={hasUpdated} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalCompanyEditForm;