import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const isAuthenticated = (): boolean => {
    return localStorage.getItem('token') !== null;
  };
  
  const PrivateRoute: React.FC = () => {
    return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
  };
  
  export default PrivateRoute;