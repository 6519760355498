import './HallAppContainer.scss';

function HallAppContainer({ children, style }) {
    return (
        <div style={style} className='container container-hallapp-wrapper'>
            <div className='container-hallapp'>
                {children}
            </div>
        </div>
    );
}

export default HallAppContainer;