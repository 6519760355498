import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './Template.scss';
import Sidebar from './Sidebar';

const NavbarTop = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <Navbar className="custom-navbar custom-navbar-top">
                <Container>
                    <FontAwesomeIcon onClick={handleShow} icon={faBars} size="lg" className='icon-menu-bottom' />
                    <Navbar.Brand>HALLAPP</Navbar.Brand>
                </Container>
            </Navbar>
            <Sidebar show={show} handleClose={handleClose}/>
        </div>
    );
};

export default NavbarTop;