import React from 'react';

const CircularImage = ({ url, width, height }) => {
  if (!url) {
    return null;
  }

  return (
    <img
      style={{borderRadius: '50%', objectFit: 'cover', width: width}}
      height={height}
      src={url}
      alt={'Imagem circular'}
    />
  );
};

export default CircularImage;