import './Employees.scss'

import { Alert, Col, Row } from "react-bootstrap";
import { LeadingActions, SwipeAction, SwipeableList, SwipeableListItem, TrailingActions } from "react-swipeable-list";
import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";

import AppLoading from "../../commons/AppLoading";
import CompanyAPI from "../company/CompanyAPI";
import ConfirmModal from "../../commons/modal/ConfirmModal";
import EmployeeCard from "./components/EmployeeCard";
import EmployeeModal from "./components/EmployeeModal";
import FloatingButton from "../../commons/buttons/FloatingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HallAppConst from "../../commons/util/HallAppConst";
import HallAppToast from "../../commons/HallAppToast";
import PullToRefresh from 'react-simple-pull-to-refresh';

function Employess() {
    const [isLoading, setIsLoading] = useState(true)
    const [showModalAddEmpoyee, setShowModalAddEmpoyee] = useState(false)
    const [employees, setEmployees] = useState([])
    const [isLoadingResendEmail, setIsLoadingResendEmail] = useState(false)
    const [employee, setEmployee] = useState([])

    const [showModalDelete, setShowModalDelete] = useState(false)
    const [isLoadingModalDelete, setIsLoadingModalDelete] = useState(false)

    const companyAPI = new CompanyAPI();
    useEffect(() => {
        getAllEmployee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllEmployee = async () => {
        setIsLoading(true)
        try {
            const response = await companyAPI.getAllEmployee(companyAPI.getCompanyManagment().id);
            setEmployees(response.data)
            setIsLoading(false)
        } catch (_) {
            setEmployees([])
            setIsLoading(false)
        }
    };

    async function resendEmail(employee) {
        setIsLoadingResendEmail(true);
        try {
            await companyAPI.resendEmailEmployee(companyAPI.getCompanyManagment().id, employee);
            setIsLoadingResendEmail(false);
            HallAppToast.success();
        } catch (_) {
            HallAppToast.error();
            setIsLoadingResendEmail(false);
        }
    }

    const editAction = (data) => (
        <LeadingActions>
            <SwipeAction onClick={() => {
                setEmployee(data)
                setShowModalAddEmpoyee(true);
            }}>
                <div className="swipe-container-employee">
                    <div className="swipe-edit-employee">
                        <FontAwesomeIcon icon={faEdit} size="lg" />
                        <div>EDITAR</div>
                    </div>
                </div>
            </SwipeAction>
        </LeadingActions>
    );

    const deleteAction = (employee) => (
        <TrailingActions>
            <SwipeAction
                destructive={false}
                onClick={() => {
                    setEmployee(employee)
                    setShowModalDelete(true);
                }}
            >
                <div className="swipe-container-employee">
                    <div className="swipe-delete-employee">
                        <FontAwesomeIcon icon={faTrash} size="lg" />
                        <div>EXCLUIR</div>
                    </div>
                </div>
            </SwipeAction>
        </TrailingActions>
    );

    const deleteEmployee = async () => {
        setIsLoadingModalDelete(true)
        await companyAPI.deleteEmployee(companyAPI.getCompanyManagment().id, employee.id)
            .then(async (_) => {
                HallAppToast.success();
                setShowModalDelete(false)
                await getAllEmployee();
            }).catch((_) => {
                HallAppToast.error(HallAppConst.GENERIC_ERROR);
            }).finally((_) => {
                setIsLoadingModalDelete(false)
            })
    }


    return (
        <>
        <PullToRefresh onRefresh={getAllEmployee}>
            <Row style={{ margin: 0 }}>
                {isLoading ? (
                    <div style={{ alignItems: 'center', textAlign: 'center', float: 'center' }}>
                        <Col xs={12}>
                            <AppLoading />
                        </Col>
                    </div>
                ) : (

                    <>
                        <Alert key={'info'} variant={'info'}>
                            Para excluir uma empresa, arraste o card para o lado <span style={{ fontWeight: 'bold' }}>Direito </span>
                            e para editar arraste para o lado <span style={{ fontWeight: 'bold' }}>Esquerdo.</span>
                        </Alert>
                        {
                            employees.map(data => (
                                <Col xs={12} sm={12} md={4} lg={2} key={data.id} style={{ marginBottom: '10px' }}>
                                    <SwipeableList style={{ width: '100%' }}>
                                        <SwipeableListItem
                                            leadingActions={editAction(data)}
                                            trailingActions={deleteAction(data)}
                                            key={data.id}
                                            fullSwipe={false}
                                        >
                                            <div style={{ width: '100%' }}>
                                                <EmployeeCard isLoadingResendEmail={isLoadingResendEmail} employee={data} resendEmail={resendEmail} />

                                            </div>
                                        </SwipeableListItem>
                                    </SwipeableList>
                                </Col>

                            ))
                        }
                    </>
                )}
            </Row>
            </PullToRefresh>
            <FloatingButton icon={faAdd} onClick={() => {
                setEmployee(null)
                setShowModalAddEmpoyee(true);
            }} />
            <EmployeeModal
                updateListEmployees={getAllEmployee}
                employee={employee}
                show={showModalAddEmpoyee}
                closeModal={() => {
                    setShowModalAddEmpoyee(false)
                }}
            />

            <ConfirmModal show={showModalDelete}
                title='Excluir'
                isLoading={isLoadingModalDelete}
                confirm={() => deleteEmployee()}
                closeModal={() => setShowModalDelete(false)}
            >
                Deseja remover o funcionario <span style={{ fontWeight: 'bold' }}>{employee?.name}</span>?
            </ConfirmModal>
        </>
    );
}

export default Employess;