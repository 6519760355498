import React, { useEffect, useState } from 'react';
import HallAppCenterContainer from '../commons/containers/HallAppCenterContainer';
import AppButton from '../commons/buttons/AppButton';

function Install() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = async () => {
        if (!deferredPrompt) return;

        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;

        if (outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
        } else {
            console.log('User dismissed the A2HS prompt');
        }

        setDeferredPrompt(null);
    };

    return (
        <>
            <div style={{ backgroundColor: '#080726', height: '100px' }}>

            </div>
            <HallAppCenterContainer>
                <div style={{ padding: '20px', textAlign: 'center', marginTop: '30px' }}>
                    <h1>Instale Nosso APP</h1>
                    <p>
                        Para uma melhor experiência, instale nossa aplicação como uma PWA:
                    </p>
                    {deferredPrompt && (
                        <div style={{ marginBottom: '20px' }}>
                            <AppButton width='100%'
                                name='Instalar APP'
                                onClick={handleInstallClick}
                                style={{ padding: '10px 20px', margin: '10px', fontSize: '16px' }} />
                        </div>
                    )}

                    {!deferredPrompt && (
                        <p>
                            Para instalar no iOS, abra o menu de compartilhamento e selecione "Adicionar à Tela de Início".
                        </p>
                    )}
                    <h2>Instruções de Instalação:</h2>
                    <h3>Android:</h3>
                    <p>1. Clique no botão "Instalar PWA".</p>
                    <p>2. Siga as instruções exibidas para adicionar à tela inicial.</p>
                    <h3>iOS:</h3>
                    <p>1. Abra o Safari e vá para nossa aplicação.</p>
                    <p>2. Clique no ícone de compartilhamento (o quadrado com uma seta).</p>
                    <p>3. Selecione "Adicionar à Tela de Início".</p>
                </div>
            </HallAppCenterContainer>
        </>
    );
}

export default Install;