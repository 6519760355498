
import Card from 'react-bootstrap/Card';
import './Card.scss';

function CustomCard({ children }) {
    return (
        <Card className='hallapp-card' body>
            <div className='containe'>
                {children}
            </div>
        </Card>
    );
}

export default CustomCard;