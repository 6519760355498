import React from 'react';
import { Spinner } from 'react-bootstrap';



const AppLoading = () => {
    return (
        <Spinner animation="border"  />
        );
};

export default AppLoading;