import { HashRouter, Route, Routes } from "react-router-dom";

import ActivateAccount from "./auth/create/ActivateAccount";
import Commands from "./pages/commands/Commands";
import Companies from "./pages/company/Companies";
import CreateAccout from "./auth/create/CreateAccout";
import EmployeeInvite from "./pages/employee/pages/EmployeeInvite";
import Employess from "./pages/employee/Employees";
import { Fragment } from "react";
import Home from "./pages/home/Home";
import Login from "./auth/login/Login";
import PrivateRoute from "./auth/PrivateRoute";
import RecoveryPassword from "./auth/recovery-password/RecoveryPassword";
import Template from "./template/Template";
import UpadatePassword from "./auth/update-password/UpdatePassword";
import CategoriesAnServices from "./pages/services/CategoriesAndServices";
import Report from "./pages/report/Report";
import Install from "./auth/Install";

export const AppRoutes = () => {
  return (
    <HashRouter>
      <Fragment>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/instalar" element={<Install />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route element={<Template />}>
              <Route index element={<Home />} />
              <Route path="inicio" element={<Home />} />
              <Route path="meus-estabelecimentos" element={<Companies />} />
              <Route path="funcionarios" element={<Employess />} />
              <Route path="comandas" element={<Commands />} />
              <Route path="servicos" element={<CategoriesAnServices />} />
              <Route path="relatorio" element={<Report />} />
            </Route>
          </Route>
          <Route path="/criar-conta" element={<CreateAccout />} />
          <Route path="/convite/:token" element={<EmployeeInvite />} />
          <Route path="/recuperar-senha" element={<RecoveryPassword />} />
          <Route path="/redefinir-senha/:token" element={<UpadatePassword />} />
          <Route path="/ativar-conta/:token" element={<ActivateAccount />} />
        </Routes>
      </Fragment>
    </HashRouter>
  );
};