import { useEffect, useState } from "react";

import AppLoading from "../../../commons/AppLoading";
import CompanyAPI from "../../company/CompanyAPI";
import { Row } from "react-bootstrap";
import error from '../../../assets/images/error.png'
import success from '../../../assets/images/sucesso.png'
import { useNavigate, useParams } from "react-router-dom";
import HallAppCenterContainer from "../../../commons/containers/HallAppCenterContainer";
import AppButton from "../../../commons/buttons/AppButton";
import AppLinkButton from "../../../commons/buttons/AppLinkButton";
import CustomContainer from "../../../commons/containers/CustomContainer";
import AuthContainer from "../../../auth/AuthContainer";

const companyAPI = new CompanyAPI();

function EmployeeInvite() {
    const [isLoading, setIsLoading] = useState(true)
    const [isSuccess, setSuccess] = useState(false)

    const { token } = useParams();

    useEffect(() => {
        const acceptedInvite = async () => {
            try {
                await companyAPI.acceptedInvite(token)
                setSuccess(true)
                setIsLoading(false)
            } catch (_) {
                setSuccess(false)
                setIsLoading(false)
            }
        };

        acceptedInvite();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();

    const toLogin = () => {
        navigate('/login');
    };

    const toCreateAccount = () => {
        navigate('/criar-conta');
    };

    return (
        <>
            <AuthContainer>
                {
                    isLoading ? <HallAppCenterContainer><AppLoading /></HallAppCenterContainer> : isSuccess ?
                        <div className="container" style={{ textAlign: 'center' }}>
                            <CustomContainer>
                                <img src={success} style={{ width: '110px', marginTop: '50px' }} alt='logo' />
                                <Row>
                                    <h1 style={{ marginTop: '20px', fontSize: '30px' }}>Parabéns!!!</h1>

                                    <span style={{ marginTop: '20px', fontSize: '18px' }}>
                                        Convite Aceito com sucesso.<br></br>
                                        Caso você ja possua conta no HallApp faça o Login clicando em <strong>JA TENHO CONTA</strong>,
                                        se não clique em <strong>NÃO TENHO CONTA</strong> para criar sua conta.
                                    </span>

                                    <div style={{ marginTop: '10px' }}>
                                        <AppButton width="300px" name="ja tenho conta" onClick={toLogin} />
                                    </div>

                                    <div style={{ marginTop: '10px' }}>
                                        <AppLinkButton width="300px" name="não tenho conta" onClick={toCreateAccount} />
                                    </div>
                                </Row>
                            </CustomContainer>
                        </div> :
                        <div className="container" style={{ textAlign: 'center' }}>
                            <img src={error} style={{ width: '110px', marginTop: '50px' }} alt='logo' />
                            <Row>
                                <h1 style={{ marginTop: '20px', fontSize: '30px' }}>Ocorreu um erro :(</h1>

                                <span style={{ marginTop: '20px', fontSize: '18px' }}>
                                    Erro ao aceitar convite, o mesmo pode ter expirado, entre em contato com estabelecimento.
                                </span>
                                <div style={{ marginTop: '10px' }}>
                                    <AppButton width="300px" name="LOGIN" onClick={toLogin} />
                                </div>
                            </Row>
                        </div>
                }
            </AuthContainer>
        </>
    );
}

export default EmployeeInvite;