import React from 'react';
import './FloatingButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FloatingButton = ({ onClick, icon }) => {
    return (
        <button className="floating-button" onClick={onClick}>
            <FontAwesomeIcon icon={icon} size="lg" />
        </button>
    );
};

export default FloatingButton;