
const NotFoundResult = () => {


    return (
        <div style={{textAlign: 'center'}}>
            <h1>Nenhum registro encontrado.</h1>
        </div>
    );
};

export default NotFoundResult;