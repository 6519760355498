import './Template.scss';

import NavbarTop from './NavbarTop';
import { Outlet } from 'react-router-dom';
import { Provider } from 'react-redux'
import React from 'react';
import { ToastContainer } from 'react-toastify';
import store from '../redux/store';

const Template = () => {
    return (
        <Provider store={store}>
            <div>
                <NavbarTop />
                <div className='container-pages' style={{ width: '100%', height: '100vh' }}>
                    <Outlet />
                    <ToastContainer />
                </div>
            </div>
        </Provider>

    );
};

export default Template;