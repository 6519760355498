import { useEffect, useState } from "react";
import HallAppCenterContainer from "../../../commons/containers/HallAppCenterContainer";
import { Alert, Modal } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import AppInput from "../../../commons/inputs/AppInput";
import AppButton from "../../../commons/buttons/AppButton";
import CategoryAPI from "../CategoryAPI";
import CompanyAPI from "../../company/CompanyAPI";
import AppLoading from "../../../commons/AppLoading";
import HallAppToast from "../../../commons/HallAppToast";

function ModalCategory({ closeShow, show, findAllCategories, categorySelected }) {
    const [initialValues, setInitialValues] = useState({
        category: '',
    });

    const [isLoading, setLoading] = useState(false);

    const { Formik } = formik;

    const schema = yup.object().shape({
        category: yup.string().required('Informe o nome da categoria').max(50, 'Categoria deve ter no maximo 50 cracteres')
    });

    const categoryAPI = new CategoryAPI();
    const companyAPI = new CompanyAPI();

    const create = async (values, { resetForm }) => {
        const data = {
            name: values.category,
            companyId: companyAPI.getCompanyManagment().id
        }
        setLoading(true)
        try {
            if (categorySelected != null && Object.keys(categorySelected).length > 0) {
                await categoryAPI.update(categorySelected.id, data);
            } else {
                await categoryAPI.create(data);
                resetForm()
            }
           
            setLoading(false)
            HallAppToast.success();
            
            if (findAllCategories) {
                findAllCategories()
            }
        } catch (_) {
            setLoading(false)
            HallAppToast.genericError();
        }
    }

    useEffect(() => {
        if (categorySelected != null && Object.keys(categorySelected).length > 0) {
            setInitialValues({
                category: categorySelected.name,
            })         
        }
    }, [categorySelected]);

    return (
        <>
            <Modal show={show} fullscreen={false} centered={true} onHide={() => closeShow()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: 'center', fontSize: '15px' }}>
                        ADICIONAR CATEGORIA
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HallAppCenterContainer>
                        <Alert key='info' variant='info'>
                            Por favor, insira um nome descritivo para a categoria, como 'Cabelo' ou 'Serviços relacionados a cabelo', que abranja claramente os serviços que você oferece.
                        </Alert>
                        <Formik
                            validationSchema={schema}
                            onSubmit={create}
                            initialValues={initialValues}
                            enableReinitialize={true}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <AppInput errors={errors}
                                        touched={touched}
                                        name='category'
                                        onChange={handleChange}
                                        value={values.category}
                                        type='text'
                                        label='Categoria' />

                                    {isLoading ? <HallAppCenterContainer><AppLoading /> </HallAppCenterContainer>:
                                        <AppButton type="submit" width='100%' name='adicionar' />
                                    }
                                </Form>
                            )}
                        </Formik>
                    </HallAppCenterContainer>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalCategory;