import './Template.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from 'react-bootstrap/Dropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import UserService from '../auth/UserService';
import { selectedCompany, setCompanies } from '../redux/actions/companyAction';
import { Nav } from 'react-bootstrap';
import SidebarItem from './SidebarItem';

const service = new UserService()

const Sidebar = ({ show, handleClose }) => {
    const [userLogged, setUserLogged] = useState(null)
    const dispatch = useDispatch();

    const companyManagement = useSelector(state => state.company.companyManagement);
    const companiesUserLogged = useSelector(state => state.company.companies);


    useEffect(() => {
        const checkUser = () => {
            const user = service.getUserData();
            setUserLogged(user)
        };
        checkUser();
        getCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function isUserAdmin() {
        const user = service.getUserData();
        return user.rules?.length !== 0 && user.rules?.includes("COMPANY");
    }


    function getCompanies() {
        const companies = JSON.parse(localStorage.getItem('companies'));
        if (companies === null) {
            dispatch(setCompanies([]));
        } else {
            dispatch(setCompanies(companies));
        }
    }

    function setCompany(company) {
        dispatch(selectedCompany(company))
    }

    function getCompanyNameSelected() {
        if (companiesUserLogged?.length === 0) return 'Você pertence a nenhuma empresa'
        if (companyManagement?.unit === null) {
            return companyManagement?.name;
        }
        return `${companyManagement?.name} - ${companyManagement?.unit}`
    }


    return (
        <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false} className="custom-sidebar">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Olá {userLogged?.name.split(' ')[0]}!</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Nav className="flex-column">
                    <hr></hr>
                    <Dropdown>
                        <Dropdown.Toggle className='dropdown-company' disabled={companiesUserLogged?.length === 0} style={{ width: '100%' }} variant="success" id="dropdown-basic">
                            {getCompanyNameSelected()}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ width: '100%' }} >
                            {
                                companiesUserLogged?.map((company) => <Dropdown.Item onClick={() => setCompany(company)} eventKey={company.id}>
                                    {company?.name} - {company?.unit}
                                </Dropdown.Item>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    <hr></hr>

                    <SidebarItem label='Estabelecimentos' path='meus-estabelecimentos' handleClose={handleClose} />
                    {isUserAdmin() && <SidebarItem label='Funcionarios' path='funcionarios' handleClose={handleClose} />}
                    {isUserAdmin() && <SidebarItem label='Categorias e Serviços' path='servicos' handleClose={handleClose} />}
                    <SidebarItem label='Comandas' path='comandas' handleClose={handleClose} />
                    {isUserAdmin() && <SidebarItem label='Relatorio' path='relatorio' handleClose={handleClose} />}

                    <hr></hr>
                    <SidebarItem label='Sair' path='login' handleClose={handleClose} />

                    <div className="profile-section">
                        <div className="profile-info">
                            <strong>{userLogged?.name}</strong>
                            <span>{userLogged?.email}</span>
                        </div>
                    </div>
                </Nav>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default Sidebar;