import React, { Fragment, useState } from "react";
import { formatDateTime, formatInReal, formatDate, formatTransactionType } from "../../../commons/util/HallAppUtil";
import { Badge } from "react-bootstrap";
import './CardCommand.scss';
import AppLinkButton from "../../../commons/buttons/AppLinkButton";
import CommandPaymentModal from "./CommandPaymentModal";
import { LeadingActions, SwipeAction, SwipeableList, SwipeableListItem, TrailingActions } from "react-swipeable-list";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "../../../commons/modal/ConfirmModal";
import CommandAPI from "../CommandAPI";
import CompanyAPI from "../../company/CompanyAPI";
import HallAppToast from "../../../commons/HallAppToast";
import ModalCommand from "./Command";
import 'react-swipeable-list/dist/styles.css';

function CardCommand({ command, updateCommands }) {

    const [showModalPayment, setShowModalPaymentd] = useState(false)
    const [isLoadingModalDelete, setIsLoadingModalDelete] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [showModalCommand, setShowModalCommand] = useState(false)

    const commandAPI = new CommandAPI();
    const companyAPI = new CompanyAPI();

    function renderStatus(status) {
        if (status === 'PAID') {
            return <Badge bg="success" style={{ width: '100%' }}>PAGO</Badge>
        } else if (status === 'PARTIAL_PAYMENT') {
            return <Badge className="custom-badge-waiting-for-payment" bg="warning" style={{ width: '100%' }}>PAGO PARCIALMENTE</Badge>
        } else if (status === 'WAITING_FOR_PAYMENT') {
            return <Badge className="custom-badge-waiting-for-payment" style={{ width: '100%' }}>AGUARDANDO PAGAMENTO</Badge>
        } else {
            return <Badge bg="secondary" style={{ width: '100%' }}>Sem informação</Badge>
        }
    }

    const editAction = () => (
        <LeadingActions>
            <SwipeAction onClick={() => {
                setShowModalCommand(true)
            }}>
                <div className="swipe-container-command">
                    <div className="swipe-edit-command">
                        <FontAwesomeIcon icon={faEdit} size="lg" />
                        <div>EDITAR</div>
                    </div>
                </div>
            </SwipeAction>
        </LeadingActions>
    );

    const deleteAction = () => (
        <TrailingActions>
            <SwipeAction
                destructive={false}
                onClick={() => {
                    setShowModalDelete(true);
                }}
            >
                <div className="swipe-container-command">
                    <div className="swipe-delete-command">
                        <FontAwesomeIcon icon={faTrash} size="lg" />
                        <div>EXCLUIR</div>
                    </div>
                </div>
            </SwipeAction>
        </TrailingActions>
    );

    async function executeDeletion() {
        setIsLoadingModalDelete(true)
        try {
            await commandAPI.delete(command.id, companyAPI.getCompanyManagment().id);
            setIsLoadingModalDelete(false)
            setShowModalDelete(false)
            HallAppToast.success();
            if (updateCommands) {
                updateCommands();
            }
        } catch (_) {
            HallAppToast.genericError();
            setIsLoadingModalDelete(false)
        } finally {
            setIsLoadingModalDelete(false)
        }
    }

    return (
        <>
            <SwipeableList style={{ width: '100%' }}>
                <SwipeableListItem
                    leadingActions={editAction(command)}
                    trailingActions={deleteAction(command)}
                    key={command.id}
                    fullSwipe={false}
                >
                    <div style={{ width: '100%' }} className="card-command">
                        <div className="d-flex justify-content-between">
                            <div>
                                <span style={{ fontWeight: 'bold' }}>{command.customerName}</span>
                                <div className="info">
                                    <p className="create-date">{formatDateTime(command.createDate)}</p>
                                </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                {renderStatus(command.status)}
                            </div>
                        </div>

                        <hr className="dashed-hr" />
                        <div className="info" style={{ textAlign: 'center' }}>
                            <p className="label">Valor</p>
                            <p>{formatInReal(command.totalValue)}</p>
                            <p className="label">Data do Serviço</p>
                            <p>{formatDate(command.serviceDate)}</p>
                            <p key={"service_"+command.id} className="label">Serviço</p>
                            <p>{command.employees.map((e, index) => (
                                <>
                                    <Fragment key={index}>
                                        {e.serviceName}{index < command.employees.length - 1 ? '/' : ''}
                                    </Fragment>
                                </>
                            ))}</p>
                            <p className="label">Resp.</p>
                            {command?.employees
                                ?.filter((e, index, self) =>
                                    index === self.findIndex(emp => emp.employeeName?.split(' ')[0] === e.employeeName?.split(' ')[0])
                                )
                                .map((e) => (
                                    <Badge key={e.employeeName} style={{ margin: '2px' }} bg="secondary">
                                        {e.employeeName?.split(' ')[0]}
                                    </Badge>
                                ))}
                            {command.paymentTypes && <p className="label">Pgts.</p>}
                            {command?.paymentTypes?.map((p) => <Badge style={{ margin: '2px' }} bg="success">{formatTransactionType(p)}</Badge>)}


                        </div>
                        <AppLinkButton width="100%" name="Pagamento" onClick={() => setShowModalPaymentd(true)} />
                    </div>
                </SwipeableListItem>
            </SwipeableList>
            {
                showModalPayment && <div style={{ zIndex: 10000, backgroundColor: 'red' }}>
                    <CommandPaymentModal
                        command={command}
                        show={showModalPayment}
                        closeShow={(value = false) => {
                            if (value && updateCommands) {
                                updateCommands();
                            }
                            setShowModalPaymentd(false);
                        }} />
                </div>
            }
            <ConfirmModal show={showModalDelete}
                title='Excluir'
                isLoading={isLoadingModalDelete}
                confirm={executeDeletion}
                closeModal={() => {
                    setShowModalDelete(false)
                }}
            >
                Deseja remover a comanda do(a) cliente <span style={{ fontWeight: 'bold' }}>{command.customerName} </span>
                no valor de <span style={{ fontWeight: 'bold' }}>{formatInReal(command.totalValue)}</span>?
            </ConfirmModal>

            {
                showModalCommand && <ModalCommand
                    show={showModalCommand}
                    command={command}
                    findAllCommands={async () => {
                        if (updateCommands) {
                            updateCommands();
                        }
                    }}
                    closeShow={() => {
                        setShowModalCommand(false);
                    }} />
            }
        </>
    );
}

export default CardCommand;