import { jwtDecode } from "jwt-decode";
import ApiService from "../commons/services/ApiService";

const apiService = new ApiService();

export default class UserService {

    URI_V1 = "/v1/users"
    AUTH = "/v1/auth"

    createUser = async (data: any) => {
        return await apiService.post(this.URI_V1, data);
    };

    login = async (data: any) => {
        return await apiService.post(this.AUTH, data).then((resp: any) => {
            localStorage.setItem('token', resp.data.accessToken)
            return resp;
        });
    };

    resetPassword = async (data: any) => {
        return await apiService.post(`${this.URI_V1}/reset-password`, data);
    };

    updatePassword = async (data: any, token: string) => {
        return await apiService.patch(`${this.URI_V1}/password/${token}`, data);
    };

    activateAccount = async (token: string) => {
        return await apiService.patch(`${this.URI_V1}/active/${token}`, null);
    };

    checkFirstAccess = async () => {
        return await apiService.get(`${this.URI_V1}/first-access`);
    };

    cleanToken = () => {
        localStorage.removeItem('token')
    };

    getUserData = () => {
        const token: any = localStorage.getItem('token')
        return jwtDecode(token)
    };

    updateToken = async () => {
        return await apiService.get(`${this.URI_V1}/token`).then((resp: any) => {
            localStorage.setItem('token', resp.data.accessToken)
            return resp;
        });;
    };

}
