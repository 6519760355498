
function HallAppCenterContainer({ children }) {
    return (
        <div className='hallapp-container'>
            <div className='hallapp-content'>
                {children}
            </div>
        </div>
    );
}

export default HallAppCenterContainer;