
import { Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AppButton from '../../commons/buttons/AppButton';
import HallAppContainer from '../../commons/containers/HallAppContainer';

const CreateAccoutSuccess = () => {
    const navigate = useNavigate();

    const toLogin = () => {
        navigate('/login');
    };

    return (
        <HallAppContainer>
            <div style={{ textAlign: 'center' }}>
                <Row>
                    <h1 style={{ marginTop: '20px', fontSize: '30px' }}>Parabéns!!!</h1>

                    <span style={{ marginTop: '20px', fontSize: '18px' }}>
                        Sua conta foi criada com sucesso, clique em <span>LOGIN</span> para entrar no aplicativo.
                    </span>
                    {/* <span style={{ marginTop: '20px', fontSize: '18px' }}>
                        Se não encontrar o e-mail na sua caixa de entrada, verifique também a pasta de spam ou lixo eletrônico.
                    </span>
                    <span style={{ marginTop: '20px', fontSize: '18px' }}>
                        Se você não recebeu o e-mail, por favor, entre em contato com nosso suporte para assistência.
                    </span> */}
                </Row>

                <div style={{ marginTop: '20px' }}>
                    <AppButton onClick={() => toLogin()} type="submit" width='100%' name='LOGIN' />
                </div>
            </div>
        </HallAppContainer>
    );
};

export default CreateAccoutSuccess;